export const ShipmentStatus = Object.freeze({
  IDLE: 'IDLE',
  PREPARING: 'PREPARING',
  PREPARED: 'PREPARED',
});

export function getStatusColor(status?: string): string {
  const idleColor = 'orange';
  if (!status) {
    return idleColor;
  }

  if (status == ShipmentStatus.IDLE) {
    return idleColor;
  }

  if (status == ShipmentStatus.PREPARING) {
    return 'blue';
  }

  if (status == ShipmentStatus.PREPARED) {
    return 'green';
  }

  return idleColor;
}
