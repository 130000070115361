import { ProductStockDetails } from '../../api/generated/data-contracts';
import { ProductStockDetailDto } from '../../types';

export class ProductStocksCore {
  static mapProductStockDetailsToProductStockDetailDto(
    productStockDetails: ProductStockDetails
  ): ProductStockDetailDto {
    return <ProductStockDetailDto>{
      warehouseId: productStockDetails.warehouse?.id,
      warehouseName: productStockDetails.warehouse?.name,
      quantity: productStockDetails.quantity,
    };
  }
}
