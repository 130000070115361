import {
  useGetAllCategoriesQuery,
  useGetAllColorsQuery,
  useGetAllModelsQuery,
} from '../store/entityRtk/apiSlice';
import { CategoryDto, EntityDto } from '../types';

interface ReturnType {
  categories: CategoryDto[];
  models: EntityDto[];
  colors: EntityDto[];
}

export const useGetAllLookups = (): ReturnType => {
  const { data: models } = useGetAllModelsQuery();
  const { data: categories } = useGetAllCategoriesQuery();
  const { data: colors } = useGetAllColorsQuery();

  return {
    colors: colors as EntityDto[],
    categories: categories as CategoryDto[],
    models: models as EntityDto[],
  };
};
