/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Platform, PlatformResponse } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Platforms<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name SavePlatform
   * @summary Save a platform
   * @request POST:/platforms
   */
  savePlatform = (data: Platform, params: RequestParams = {}) =>
    this.request<PlatformResponse, any>({
      path: `/platforms`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name FindAllPlatforms
   * @summary Find all platforms
   * @request GET:/platforms
   */
  findAllPlatforms = (params: RequestParams = {}) =>
    this.request<PlatformResponse[], any>({
      path: `/platforms`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name UpdatePlatform
   * @summary Update a platform
   * @request PUT:/platforms/{platformId}
   */
  updatePlatform = (
    platformId: number,
    data: Platform,
    params: RequestParams = {}
  ) =>
    this.request<PlatformResponse, any>({
      path: `/platforms/${platformId}`,
      method: 'PUT',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
