import { InventarioColumnType, TableTypes } from '../inventario.types';

export const getIdCell = <T,>(): InventarioColumnType<T> => {
  return {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 90,
    type: [TableTypes.NUMBER_FILTER],
  };
};
