/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ProductDeductStock,
  ProductReStock,
  ProductStockDetails,
  WarehouseProductStockResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class ProductStocks<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name Restock
   * @summary Append quantity to product stock
   * @request PUT:/product-stocks/restock
   */
  restock = (data: ProductReStock, params: RequestParams = {}) =>
    this.request<WarehouseProductStockResponse, any>({
      path: `/product-stocks/restock`,
      method: 'PUT',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name Deduct
   * @summary Deduct quantity from product stock
   * @request PUT:/product-stocks/deduct
   */
  deduct = (data: ProductDeductStock, params: RequestParams = {}) =>
    this.request<WarehouseProductStockResponse, any>({
      path: `/product-stocks/deduct`,
      method: 'PUT',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name GetWarehouseProductStock
   * @summary Get Warehouse ProductStock
   * @request GET:/product-stocks/products/{productId}/warhouses/{warehouseId}
   */
  getWarehouseProductStock = (
    productId: number,
    warehouseId: number,
    params: RequestParams = {}
  ) =>
    this.request<WarehouseProductStockResponse, any>({
      path: `/product-stocks/products/${productId}/warhouses/${warehouseId}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name GetProductStocksDetails
   * @summary Get ProductStock details
   * @request GET:/product-stocks/products/{productId}
   */
  getProductStocksDetails = (productId: number, params: RequestParams = {}) =>
    this.request<ProductStockDetails[], any>({
      path: `/product-stocks/products/${productId}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
}
