import { Button, Input, Space } from 'antd';
import { LockFilled, UnlockFilled } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';

type InputModes = 'manual' | 'auto';

interface Props {
  onChange: (value?: string) => void;
  value?: string;
  automaticName?: string;
}

export const ProductName = (props: Props) => {
  const [inputMode, setInputMode] = useState<InputModes>('auto');
  const manualValue = useRef(props.value);
  const isInitialRender = useRef(true);

  useEffect(() => {
    if (!isInitialRender.current && props.automaticName !== '') {
      props.onChange(props.automaticName);
      manualValue.current = props.automaticName;
    }
  }, [props.automaticName]);

  useEffect(() => {
    isInitialRender.current = false;
  }, []);

  const handleOnClick = () => {
    setInputMode(inputMode === 'auto' ? 'manual' : 'auto');

    if (inputMode === 'manual') {
      props.onChange(props.automaticName);
    } else {
      props.onChange(manualValue.current);
    }
  };

  const icon = inputMode === 'auto' ? <LockFilled /> : <UnlockFilled />;

  return (
    <Space.Compact style={{ width: '100%' }}>
      <Input
        placeholder='Product Name'
        onChange={(e) => {
          props.onChange(e.target.value);
          manualValue.current = e.target.value;
        }}
        value={props.value}
        disabled={inputMode === 'auto'}
      />
      <Button onClick={handleOnClick} type='primary' icon={icon}></Button>
    </Space.Compact>
  );
};
