import { CategoryDto, EntityDto } from '../../../types';
import { TagsList } from '../../../components/tag/tagList';
import { Flex, Segmented, Space, Tree, TreeDataNode, Typography } from 'antd';
import { CategoryEditPopover } from '../../../components/category/categoryEditPopover';
import { useMemo, useState } from 'react';
import { CategoryAddPopover } from '../../../components/category/categoryAddPopover';
import { useGetAllLookups } from '../../../hooks/useGetAllLookups';
import { InventarioTable } from '../../../components/table/inventarioTable';
import {
  InventarioColumnType,
  TableTypes,
} from '../../../components/table/inventario.types';
import { BarsOutlined, DeploymentUnitOutlined } from '@ant-design/icons';

export const CategoriesListingPage = () => {
  const { categories } = useGetAllLookups();

  const categoriesTree: TreeDataNode[] = useMemo(() => {
    const treeData: TreeDataNode[] = [];
    categories?.forEach?.((category: CategoryDto) => {
      treeData.push({
        title: category.name,
        key: category.id,
        children: category.subCategories?.map((subc) => ({
          title: subc.name,
          key: `${category.name}-${subc.name}`,
        })),
      });
    });

    return treeData;
  }, [categories]);

  const [treeMode, setTreeMode] = useState(false);

  const columns: InventarioColumnType<CategoryDto>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 90,
      type: [TableTypes.NUMBER_FILTER],
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: 400,
      key: 'name',
      type: [TableTypes.TEXT_FILTER],
    },
    {
      title: 'Subcategories',
      dataIndex: 'subCategories',
      key: 'Subcategories',
      type: [TableTypes.TEXT_FILTER],
      filterFunction: (text, record) => {
        return !!record.subCategories?.find((subC) =>
          subC.name?.toLowerCase().includes(text?.toString()?.toLowerCase())
        );
      },
      render: (subCategories: EntityDto[]) => {
        return (
          <TagsList color={'blue'} tags={subCategories} optionLabel={'name'} />
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text: string, record: EntityDto) => (
        <CategoryEditPopover initialRecord={record} entityName={'Category'} />
      ),
    },
  ];

  return (
    <Space direction='vertical' size={'small'} style={{ width: '100%' }}>
      <Flex align={'center'} justify={'space-between'}>
        <Typography.Title>Category Listing page</Typography.Title>
        <Flex gap={5} align={'center'}>
          <CategoryAddPopover entityName={'Category'} />
          <Segmented
            value={treeMode}
            onChange={(value) => {
              setTreeMode(value);
            }}
            options={[
              { value: false, icon: <BarsOutlined /> },
              { value: true, icon: <DeploymentUnitOutlined /> },
            ]}
          />
        </Flex>
      </Flex>
      {!treeMode && (
        <InventarioTable
          dataSource={categories}
          columns={columns}
          rowKey={(record) => record.id}
        />
      )}
      {treeMode && <Tree showLine treeData={categoriesTree} />}
    </Space>
  );
};
