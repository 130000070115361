import { Button, Card, Flex, notification, Typography, Upload } from 'antd';
import {
  DownloadOutlined,
  FileAddOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { ExcelCore } from '../../core/excel/Excel';
import { inventoriaApi } from '../../api/inventoria-api-factory/inventoriaApiFactory';
import { useState } from 'react';
import { api } from '../../store/entityRtk/apiSlice';
import Link from 'antd/es/typography/Link';
import { BaseUrl } from '../../constants';

async function pollUntilDone(uploadedId: number, interval = 1000) {
  try {
    const response = await inventoriaApi.tasks.findTaskById(uploadedId);
    if (response.data.status === 'COMPLETED') {
      return true;
    } else {
      // If status is not 'Done', wait for the specified interval and then poll again
      await new Promise((resolve) => setTimeout(resolve, interval));
      return pollUntilDone(uploadedId, interval); // Recursive call
    }
  } catch (error) {
    //handle error
  }
}

export const UploadExcelPage = () => {
  const [loading, setLoading] = useState(false);

  return (
    <Flex style={{ height: '100%' }} justify={'center'} align={'center'}>
      <Card style={{ width: 450 }}>
        <Typography.Title level={4} style={{ marginBottom: 16 }}>
          <FileAddOutlined /> Upload Excel Sheet
        </Typography.Title>
        <Typography.Paragraph type={'secondary'} style={{ marginBottom: 24 }}>
          Make sure your file is in .xlsx format.{' '}
          <Flex style={{ flexDirection: 'column' }}>
            <Link
              href={`${BaseUrl}/excel/download-dictionary-insertion-template`}
            >
              <DownloadOutlined style={{ marginRight: 5 }} />
              Dictionary Template
            </Link>
            <Link
              style={{ marginRight: 5 }}
              href={`${BaseUrl}/excel/download-platform-linking-insertion-template`}
            >
              <DownloadOutlined style={{ marginRight: 5 }} />
              Product Linking Template
            </Link>
          </Flex>
        </Typography.Paragraph>
        <Upload
          itemRender={() => null}
          customRequest={async (params) => {
            try {
              setLoading(true);
              const uploadedId = await ExcelCore.uploadExcelSheet(params);
              if (uploadedId) {
                const res =
                  await inventoriaApi.excel.startExcelInsertion(uploadedId);
                await pollUntilDone(res?.data?.id as number, 1000);
                notification.success({
                  message: 'Excel Uploaded Successfully',
                });
                api.util?.invalidateTags(['Colors', 'Models']);
              }
            } catch (error) {
              /// handle error
            } finally {
              setLoading(false);
            }
          }}
        >
          <Button
            disabled={loading}
            loading={loading}
            icon={<UploadOutlined />}
          >
            Click to Upload
          </Button>
        </Upload>
      </Card>
    </Flex>
  );
};
