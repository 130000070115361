import { ArrowRightOutlined } from '@ant-design/icons';
import { Flex } from 'antd';

interface Props {
  oldStock: number;
  modifiedStock?: number;
  adjustmentType: 'add' | 'deduct';
}

const newStockCalculator = (
  oldStock: number,
  newStock: number,
  adjustmentType: 'add' | 'deduct'
) => {
  const newStockSigned = adjustmentType === 'add' ? newStock : -1 * newStock;
  return oldStock + newStockSigned;
};

export const StockCell = (props: Props) => {
  return (
    <Flex gap={10} style={{ width: 100 }}>
      <div>{props.oldStock}</div>
      {props.modifiedStock !== undefined && props.modifiedStock > 0 && (
        <>
          <ArrowRightOutlined
            style={{
              color: props.adjustmentType === 'deduct' ? 'red' : 'green',
            }}
          />{' '}
          {newStockCalculator(
            props.oldStock,
            props.modifiedStock,
            props.adjustmentType
          )}
        </>
      )}
    </Flex>
  );
};
