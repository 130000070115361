import { Button, Flex, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useGetDecisionMakingRequestPageColumns } from './useGetDecisionMakingRequestPageColumns';
import { NavLink } from 'react-router-dom';
import { InventarioTable } from '../../../components/table/inventarioTable';
import { inventoriaApi } from '../../../api/inventoria-api-factory/inventoriaApiFactory';
import { Config } from '../../../config';

export const DecisionMakingRequestListingPage = () => {
  const columns = useGetDecisionMakingRequestPageColumns();

  return (
    <div>
      <Flex align={'center'} justify={'space-between'}>
        <Typography.Title>Decision Making Request</Typography.Title>
        <NavLink
          style={{ textAlign: 'right', display: 'block' }}
          to={'/decision-making-request/add'}
        >
          <Button type={'primary'} icon={<PlusOutlined />}>
            Add New Request
          </Button>
        </NavLink>
      </Flex>
      <InventarioTable
        tableLayout={'fixed'}
        bordered
        rowKey={'id'}
        columns={columns}
        pagination={Config.pagination}
        serverSideApiFunction={
          inventoriaApi.decisionRequest.findAllDecisionRequests
        }
        serverSideTable
        scroll={{
          scrollToFirstRowOnChange: false,
          y: 'calc(100vh - 370px)',
          x: '900px',
        }}
      />
    </div>
  );
};
