import {
  Button,
  Card,
  Carousel,
  Flex,
  Form,
  Image,
  Input,
  Typography,
} from 'antd';
import styled from 'styled-components';
import { ShipmentProduct } from '../../api/generated/data-contracts';
import React from 'react';
import { useForm } from 'antd/es/form/Form';
import { RuleObject, StoreValue } from 'rc-field-form/lib/interface';

const StyledCarousel = styled(Carousel)`
  img {
    object-fit: contain;
  }
`;

const imageRenderer = (imageUrl: string) => {
  return (
    <Image
      height={200}
      width={'100%'}
      src={imageUrl}
      style={{ objectFit: 'contain' }}
      fallback='https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/832px-No-Image-Placeholder.svg.png'
    />
  );
};

const StyledCard = styled(Card)`
  overflow: hidden;
  display: flex;

  .ant-card-body {
    width: 100%;
    padding: 0;
  }

  min-width: 250px;
  max-width: 350px;
`;

function ProductImages({ productImagesUrls }: { productImagesUrls: string[] }) {
  return (
    <>
      {!!productImagesUrls.length && (
        <StyledCarousel dotPosition={'bottom'}>
          {productImagesUrls.map((productImageUrl) => {
            return imageRenderer(productImageUrl);
          })}
        </StyledCarousel>
      )}
      {!productImagesUrls.length && (
        <Image
          preview={false}
          height={200}
          width={'100%'}
          style={{ objectFit: 'contain' }}
          src='https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/832px-No-Image-Placeholder.svg.png'
        />
      )}
    </>
  );
}

function ProductDetail({ title, value }: { title: string; value?: string }) {
  return (
    <Flex gap={10} justify={'space-between'}>
      <Typography.Paragraph style={{ whiteSpace: 'nowrap' }} type={'secondary'}>
        {title}
      </Typography.Paragraph>
      <Typography.Paragraph style={{ marginBottom: '7px', textAlign: 'right' }}>
        {value}
      </Typography.Paragraph>
    </Flex>
  );
}

export const ShipmentProductCard = ({
  shipmentProduct,
}: {
  shipmentProduct: ShipmentProduct;
}) => {
  const product = shipmentProduct.product;
  const [form] = useForm();
  const productImagesUrls =
    (product.images?.filter(Boolean).map((img) => img.imageUrl) as string[]) ??
    [];

  return (
    <StyledCard bordered={true}>
      <ProductImages productImagesUrls={productImagesUrls} />
      <div style={{ padding: '15px' }}>
        <ProductDetail title={'Name:'} value={product.name} />
        <ProductDetail title={'SKU:'} value={product.internalProductCodeSku} />
        <ProductDetail
          title={'Printable SKU:'}
          value={shipmentProduct.printableSku}
        />
        <ProductDetail
          title={'Quantity:'}
          value={shipmentProduct.quantityToShip.toString()}
        />
        <Flex gap={10} justify={'flex-end'}>
          <Form form={form}>
            <Form.Item
              // validateStatus={'error'}
              // help={'fuck off'}
              name={'quantityToPrint'}
              rules={[
                { required: true, message: 'Required' },
                { validator: validateNumberRange },
              ]}
              style={{
                width: '62px',
              }}
            >
              <Input
                defaultValue={shipmentProduct.quantityToShip}
                type={'number'}
                min={1}
              />
            </Form.Item>
          </Form>
          <Button>Print</Button>
        </Flex>
      </div>
    </StyledCard>
  );
};

const validateNumberRange = async (
  _: RuleObject,
  value: StoreValue
): Promise<void> => {
  if (value === undefined || value === null) {
    return;
  }
  if (parseInt(value) < 1) {
    throw new Error('Min: 1');
  }
};
