import { ColumnsType } from 'antd/es/table';
import { PlatformProductDto } from '../../types';

import { LinkCell } from '../table/cells/linkCell';

export const useGetPlatformProductColumns =
  (): ColumnsType<PlatformProductDto> => {
    return [
      {
        title: 'Platform SKU',
        key: 'platformSku',
        dataIndex: 'platformSku',
      },
      {
        title: 'url',
        width: '50%',
        key: 'url',
        render: (_, record) => {
          return <LinkCell link={record.url} />;
        },
      },
    ];
  };
