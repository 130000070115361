/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Model,
  ModelPageResponse,
  ModelResponse,
  QueryOptions,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Models<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name SaveModel
   * @summary Save a model
   * @request POST:/models
   */
  saveModel = (data: Model, params: RequestParams = {}) =>
    this.request<ModelResponse, any>({
      path: `/models`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name FindAllModels
   * @summary Find all models
   * @request GET:/models
   */
  findAllModels = (
    query?: {
      /** Paging, sorting, and filtration parameters */
      queryOptions?: QueryOptions;
    },
    params: RequestParams = {}
  ) =>
    this.request<ModelPageResponse, any>({
      path: `/models`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name UpdateModel
   * @summary Update model
   * @request PUT:/models/{modelId}
   */
  updateModel = (modelId: number, data: Model, params: RequestParams = {}) =>
    this.request<ModelResponse, any>({
      path: `/models/${modelId}`,
      method: 'PUT',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
