import {
  Button,
  Modal,
  Table,
  InputNumber,
  Radio,
  Space,
  Typography,
  Flex,
  Popconfirm,
  notification,
} from 'antd';
import {
  RefObject,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { merge } from 'lodash';
import { StockCell } from './stockCell';
import { inventoriaApi } from '../../../api/inventoria-api-factory/inventoriaApiFactory';

import { ProductStocksCore } from '../../../core/product-stocks/ProductStocks';
import { ProductStockDetailDto } from '../../../types';

interface Props {
  productId: number;
}

interface ModifiedProductStock {
  stockLevel: number;
  adjustmentType: 'add' | 'deduct';
}

export const ManageProductStock = (props: Props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSaveEnabled, setIsSavedEnabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const ref = useRef<{
    getModifiedWarehouses: () => Record<number, ModifiedProductStock>;
  }>(null);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    const modifiedProductStocks = ref.current?.getModifiedWarehouses() ?? {};
    const promises: Promise<any>[] = [];

    Object.entries(modifiedProductStocks).forEach(([key, value]) => {
      if (value.adjustmentType === 'deduct') {
        promises.push(
          inventoriaApi.productStocks.deduct({
            productId: props.productId,
            quantity: value.stockLevel,
            warehouseId: parseInt(key),
          })
        );
      } else {
        promises.push(
          inventoriaApi.productStocks.restock({
            productId: props.productId,
            quantity: value.stockLevel,
            warehouseId: parseInt(key),
          })
        );
      }
    });

    Promise.all(promises)
      .then(() => {
        setIsModalVisible(false);
        notification.success({ message: 'Product Stocks Updated Correctly' });
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });

    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button type='link' icon={<ShoppingCartOutlined />} onClick={showModal} />
      <Modal
        destroyOnClose
        onCancel={handleCancel}
        title='Manage Product Stocks'
        open={isModalVisible}
        width={800}
        footer={
          <Flex justify={'flex-end'} gap={6}>
            <Popconfirm
              title='Title'
              description='Are you sure you want to save?'
              onConfirm={handleOk}
            >
              <Button
                loading={loading}
                disabled={!isSaveEnabled}
                type='primary'
              >
                Save
              </Button>
            </Popconfirm>
            <Button onClick={handleCancel}>Cancel</Button>
          </Flex>
        }
      >
        <Typography.Paragraph>
          Manage the stocks for each warehouse. Adjust the quantity by either
          adding or deducting from the current stock.
        </Typography.Paragraph>
        <ModalStocksBody
          changeIsSaveEnabled={setIsSavedEnabled}
          productId={props.productId}
          dataRef={ref}
        />
      </Modal>
    </>
  );
};

export const ModalStocksBody = (
  props: Props & {
    changeIsSaveEnabled: (e: boolean) => void;
    dataRef: RefObject<{
      getModifiedWarehouses: () => Record<number, ModifiedProductStock>;
    }>;
  }
) => {
  const [warehousesDetails, setWarehousesDetails] = useState<
    ProductStockDetailDto[]
  >([]);

  const [modifiedWarehouses, setModifiedWarehouses] = useState<
    Record<number, ModifiedProductStock>
  >({});

  useImperativeHandle(props.dataRef, () => ({
    getModifiedWarehouses: () => {
      return modifiedWarehouses;
    },
  }));

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    inventoriaApi.productStocks
      .getProductStocksDetails(props.productId)
      .then((res) => {
        const dataMapped = res.data.map(
          ProductStocksCore.mapProductStockDetailsToProductStockDetailDto
        );
        setWarehousesDetails(dataMapped);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleStockChange = (warehouseId: number, stockLevel: number) => {
    setModifiedWarehouses((prevWarehouses) =>
      merge({}, prevWarehouses, { [warehouseId]: { stockLevel } })
    );
  };

  const handleAdjustmentTypeChange = (
    warehouseId: number,
    adjustmentType: 'add' | 'deduct'
  ) => {
    setModifiedWarehouses((prevWarehouses) =>
      merge({}, prevWarehouses, { [warehouseId]: { adjustmentType } })
    );
  };

  const isSaveEnabled: boolean = useMemo(() => {
    return !!Object.keys(modifiedWarehouses).length;
  }, [Object.keys(modifiedWarehouses).length]);

  useEffect(() => {
    props.changeIsSaveEnabled(isSaveEnabled);
  }, [isSaveEnabled]);

  const columns = [
    {
      title: 'Warehouse',
      dataIndex: 'warehouseName',
      key: 'warehouseName',
    },
    {
      title: 'Stock',
      dataIndex: 'stock',
      key: 'stock',
      render: (_: any, record: ProductStockDetailDto) => (
        <StockCell
          oldStock={record.quantity}
          modifiedStock={modifiedWarehouses?.[record.warehouseId]?.stockLevel}
          adjustmentType={
            modifiedWarehouses?.[record.warehouseId]?.adjustmentType ?? 'add'
          }
        />
      ),
    },
    {
      title: 'Adjustment Type',
      key: 'adjustmentType',
      render: (_: any, record: ProductStockDetailDto) => (
        <Radio.Group
          value={
            modifiedWarehouses?.[record.warehouseId]?.adjustmentType ?? 'add'
          }
          onChange={(e) => {
            const modifiedStockLevel =
              modifiedWarehouses[record.warehouseId]?.stockLevel ?? 0;
            const currentStockLevel = record.quantity;

            if (
              e.target.value === 'deduct' &&
              modifiedStockLevel &&
              currentStockLevel < modifiedStockLevel
            ) {
              handleStockChange(record.warehouseId, 0);
            }
            handleAdjustmentTypeChange(record.warehouseId, e.target.value);
          }}
        >
          <Space direction='vertical'>
            <Radio value='add'>Add Stock</Radio>
            <Radio value='deduct'>Deduct Stock</Radio>
          </Space>
        </Radio.Group>
      ),
    },
    {
      title: 'New Stock',
      key: 'newStock',
      render: (_: any, record: ProductStockDetailDto) => (
        <InputNumber
          min={0}
          max={
            modifiedWarehouses?.[record.warehouseId]?.adjustmentType ===
            'deduct'
              ? record.quantity
              : undefined
          }
          value={modifiedWarehouses?.[record.warehouseId]?.stockLevel ?? 0}
          onChange={(value) =>
            handleStockChange(record.warehouseId, value as number)
          }
          style={{ width: '100%' }}
        />
      ),
    },
  ];

  return (
    <Table
      rowKey={(record) => record.warehouseId}
      loading={loading}
      dataSource={warehousesDetails}
      columns={columns}
      pagination={false}
    />
  );
};
