import React from 'react';
import { Result } from 'antd';

import { InventoriaRoutes } from '../../routes/routes';
import { NavLink } from 'react-router-dom';

export const NotFoundPage = () => {
  return (
    <Result
      style={{ height: '100vh' }}
      status='404'
      title='404'
      subTitle='Sorry, the page you visited does not exist.'
      extra={<NavLink to={InventoriaRoutes.PRODUCTS}>Back To Home</NavLink>}
    />
  );
};
