import { Tag } from 'antd';
import { DecisionMakingRequestStateTypeEnum } from '../../types';

export const DecisionMakingRequestStateTag = ({
  state,
}: {
  state: DecisionMakingRequestStateTypeEnum;
}) => {
  const item = {
    [DecisionMakingRequestStateTypeEnum.CONFIRMED]: (
      <Tag color={'green'}>Confirmed</Tag>
    ),
    [DecisionMakingRequestStateTypeEnum.PENDING]: (
      <Tag color={'yellow'}>Pending</Tag>
    ),
  };

  return item[state];
};
