import { SelectLabelValue } from './types';
import type { FilterValue } from 'antd/es/table/interface';
import { isEmpty } from 'lodash';
import { inventoriaApi } from './api/inventoria-api-factory/inventoriaApiFactory';

export const mapToSelectOptionsType = <T>(
  data: T[],
  optionLabel: string & keyof T,
  optionValue: string & keyof T,
  mode?: 'tags'
): { label: string; value: string | number }[] => {
  return (
    data?.map?.((item) => {
      if (mode === 'tags') {
        return {
          label: item?.[optionLabel] as string,
          value: `${item?.[optionValue]}`,
        };
      }
      return {
        ...item,
        label: item?.[optionLabel] as string,
        value: item?.[optionValue] as string | number,
      };
    }) ?? []
  );
};

export const getValueFromSelect = (value: SelectLabelValue) => value.value;
export const getLabelFromSelect = (value?: SelectLabelValue) =>
  value?.label ?? '';

export const defaultFilterFunction = (
  input: string,
  option?: SelectLabelValue
): boolean => !!option?.label?.toLowerCase()?.includes?.(input?.toLowerCase());

export const mapAntdTableFiltersToRSQL = (
  filters: Record<string, FilterValue | null>,
  keyNameMap?: Record<string, string>
): string => {
  const rsqlFilters: string[] = [];
  for (const [key, columnFilterValues] of Object.entries(filters)) {
    const keyName = keyNameMap?.[key] ?? key;

    if (isEmpty(columnFilterValues?.[0]?.toString?.())) {
      continue;
    }
    if (typeof columnFilterValues?.[0] === 'number') {
      const filterQuery = columnFilterValues
        .map((filterValue) => `${keyName}=='${filterValue}'`)
        .join(',');

      rsqlFilters.push(`(${filterQuery})`);
    }
    if (typeof columnFilterValues?.[0] === 'string') {
      const filterQuery = columnFilterValues
        .map((filterValue) => `${keyName}=ilike='${filterValue}'`)
        .join(',');

      rsqlFilters.push(`(${filterQuery})`);
    } else if (columnFilterValues !== null) {
      const filterQuery = columnFilterValues
        .map((filterValue) => `${keyName}==${filterValue}`)
        .join(',');
      rsqlFilters.push(`(${filterQuery})`);
    }
  }

  // Join each filter condition with 'and' operator
  return rsqlFilters.join(';');
};

export const mapDefaultTableFiltersToColumnFilters = (
  filterArray?: number[] | null
) => filterArray?.map((filter) => filter.toString());

export async function pollUntilDone(uploadedId: number, interval = 1000) {
  try {
    const response = await inventoriaApi.tasks.findTaskById(uploadedId);
    if (response.data.status === 'COMPLETED') {
      return response;
    }

    if (response.data.status === 'FAILED') {
      return response;
    } else {
      // If status is not 'Done', wait for the specified interval and then poll again
      await new Promise((resolve) => setTimeout(resolve, interval));
      return pollUntilDone(uploadedId, interval); // Recursive call
    }
  } catch (error) {
    //handle error
  }
}
