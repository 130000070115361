import {
  AntdColumnType,
  InventarioColumnType,
  InventarioProps,
  TableTypes,
} from './inventario.types';
import { getStringFilterProp } from './table-search/stringSearchProps';
import { ColumnsType } from 'antd/es/table';
import { getNumberFilterProp } from './table-search/numberSearchProps';

export const ColumnTypeMapper = <T>(
  column: InventarioColumnType<T>,
  props: InventarioProps<T>
): AntdColumnType<T> => {
  let output: AntdColumnType<T> = {};
  const types = column.type;

  types?.forEach?.((type) => {
    const localFilter = !props.serverSideTable;

    if (type === TableTypes.TEXT_FILTER) {
      output = {
        ...output,
        ...getStringFilterProp<T>({
          dataIndex: column.dataIndex as string,
          localFilter: localFilter,
          filterFunction: column.filterFunction,
        }),
      };
    }
    if (type === TableTypes.NUMBER_FILTER) {
      output = {
        ...output,
        ...getNumberFilterProp<T>({
          dataIndex: column.dataIndex as string,
          localFilter: localFilter,
          filterFunction: column.filterFunction,
        }),
      };
    }
  });

  return output;
};

export const mapInventarioColumnToTableColumn = <T>(
  column: InventarioColumnType<T>,
  props: InventarioProps<T>
): AntdColumnType<T> => {
  const typeMapped = ColumnTypeMapper(column, props);

  return { ...column, ...typeMapped };
};

export const mapInventarioColumnsToTableColumns = <T>(
  columns: InventarioColumnType<T>[],
  props: InventarioProps<T>
): ColumnsType<T> => {
  return columns.map((col) => mapInventarioColumnToTableColumn(col, props));
};
