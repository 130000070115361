import { InventarioTable } from '../table/inventarioTable';
import { InventarioColumnType } from '../table/inventario.types';
import { PlatformProductDecisionTableGridRow } from '../../types';
import { Checkbox } from 'antd';
import { DecisionMakingRequestWarehouseTable } from './decisionMakingRequestWarehouseTable';
import { useAppDispatch } from '../../store/useAppDispatch';
import { decisionMakingRequestActions } from '../../store/decision-making-request/decisionMakingRequestActions';
import { LinkCell } from '../table/cells/linkCell';
import React from 'react';

interface Props {
  data: PlatformProductDecisionTableGridRow[];
  productIndex: number;
}

export const PlatformProductSubTable = (props: Props) => {
  const dispatch = useAppDispatch();
  const columns: InventarioColumnType<PlatformProductDecisionTableGridRow>[] = [
    {
      title: 'Platform Product Link',
      dataIndex: 'platformProductUrl',
      render: (_, record) => {
        return <LinkCell link={record.platformProductUrl ?? ''} />;
      },
    },
    {
      title: 'Platform Product SKU',
      dataIndex: 'platformProductSku',
    },
    {
      title: 'Platform Sales',
      dataIndex: 'platformSales',
    },
    {
      title: 'Will Be Prepared',
      dataIndex: 'willBePrepared',
      render: (value, record, index) => {
        return (
          <Checkbox
            checked={value}
            onChange={(e) => {
              dispatch(
                decisionMakingRequestActions.updateWillBePrepared({
                  productIndex: props.productIndex,
                  decisionIndex: index,
                  willBePrepared: e.target.checked,
                })
              );
            }}
          />
        );
      },
    },
  ];
  return (
    <InventarioTable
      pagination={false}
      dataSource={props.data}
      columns={columns}
      expandable={{
        expandedRowRender: (record, index) => (
          <DecisionMakingRequestWarehouseTable
            productIndex={props.productIndex}
            decisionIndex={index}
            data={record.decidedWarehouseShipments}
          />
        ),
      }}
      rowKey='platformProductId'
    />
  );
};
