import { Select, Typography } from 'antd';
import { defaultFilterFunction } from '../../utils';
import { useGetAllPlatformsQuery } from '../../store/entityRtk/apiSlice';
import { useEffect } from 'react';
import { SelectLabelValue } from '../../types';
import { useAppDispatch, useAppSelector } from '../../store/useAppDispatch';
import { decisionMakingRequestActions } from '../../store/decision-making-request/decisionMakingRequestActions';
import {
  DecisionMakingRequestInitialState,
  decisionMakingRequestStateSelector,
} from '../../store/decision-making-request/decisionMakingRequest';

export const DecisionMakingRequestFormData = () => {
  const { data } = useGetAllPlatformsQuery();
  const dispatch = useAppDispatch();
  const decisionMakingRequestState = useAppSelector(
    decisionMakingRequestStateSelector
  );

  const dispatchValue = (
    action: Partial<DecisionMakingRequestInitialState>
  ) => {
    dispatch(decisionMakingRequestActions.changeState(action));
  };

  useEffect(() => {
    // When the data load successfully set the first platform as initial in the store
    if (data?.[0] && !decisionMakingRequestState.currentPlatform) {
      const defaultValue: SelectLabelValue = {
        label: data?.[0].name as string,
        value: data?.[0].id as number,
      };
      dispatchValue({ currentPlatform: defaultValue });
    }
  }, [data]);

  return (
    <div>
      <Typography.Text>Platform</Typography.Text>
      <Select
        style={{ display: 'block', width: 'min(100%, 400px)' }}
        value={decisionMakingRequestState.currentPlatform}
        showSearch
        options={data?.map?.((item) => ({
          label: item?.name,
          value: item?.id,
        }))}
        filterOption={defaultFilterFunction}
        labelInValue
        onChange={(selectedOption) => {
          dispatchValue({ currentPlatform: selectedOption });
        }}
      />
    </div>
  );
};
