import { Input, Select } from 'antd';
import styled from 'styled-components';

import { EntityCore } from '../../../core/entity/Entity';
import { CategoryCore } from '../../../core/category/Category';
import { useGetProductsData } from '../../../pages/products/products-listing/hooks/useGetProductsData';
import { useGetAllLookups } from '../../../hooks/useGetAllLookups';

const ProductFiltersContainer = styled.div`
  width: 200px;
  padding: 10px;
  padding-left: 0px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const ProductGridFilters = () => {
  const { categories, models, colors } = useGetAllLookups();

  const { onChangeFilterGrid, filters } = useGetProductsData();

  return (
    <ProductFiltersContainer>
      <span>Product Name</span>
      <Input
        allowClear
        value={filters?.name?.[0] as string}
        placeholder={'Product Name'}
        onChange={(e) => {
          onChangeFilterGrid({
            key: 'name',
            value: e.target.value ? [e.target.value] : null,
          });
        }}
      />
      <span>Internal SKU</span>
      <Input
        allowClear
        value={filters?.internalProductCodeSku?.[0] as string}
        placeholder={'Internal SKU'}
        onChange={(e) => {
          onChangeFilterGrid({
            key: 'internalProductCodeSku',
            value: e.target.value ? [e.target.value] : null,
          });
        }}
      />
      <span>Product Description</span>
      <Input
        allowClear
        value={filters?.productDescription?.[0] as string}
        placeholder={'Product Description'}
        onChange={(e) => {
          onChangeFilterGrid({
            key: 'productDescription',
            value: e.target.value ? [e.target.value] : null,
          });
        }}
      />
      <span>Model</span>
      <Select
        allowClear
        value={filters.model}
        mode={'multiple'}
        options={models?.map(
          EntityCore.mapEntityDtoItemsToLabelValueSelectValue
        )}
        placeholder={'Model'}
        style={{ width: '100%' }}
        onChange={(selectedOptions) => {
          onChangeFilterGrid({
            key: 'model',
            value: selectedOptions,
          });
        }}
      />
      <span>Category</span>
      <Select
        allowClear
        mode={'multiple'}
        value={filters.category}
        options={categories?.map(
          EntityCore.mapEntityDtoItemsToLabelValueSelectValue
        )}
        placeholder={'Model'}
        style={{ width: '100%' }}
        onChange={(selectedOptions) => {
          onChangeFilterGrid({
            key: 'category',
            value: selectedOptions,
          });
        }}
      />
      <span>Subcategory</span>
      <Select
        allowClear
        mode={'multiple'}
        value={filters.subCategory}
        options={CategoryCore.getAllSubCategories(categories)?.map?.(
          EntityCore.mapEntityDtoItemsToLabelValueSelectValue
        )}
        placeholder={'Subcategory'}
        style={{ width: '100%' }}
        onChange={(selectedOptions) => {
          onChangeFilterGrid({
            key: 'subCategory',
            value: selectedOptions,
          });
        }}
      />
      <span>Color</span>
      <Select
        allowClear
        mode={'multiple'}
        defaultValue={filters.color}
        options={colors?.map(
          EntityCore.mapEntityDtoItemsToLabelValueSelectValue
        )}
        placeholder={'Color'}
        onChange={(selectedOptions) => {
          onChangeFilterGrid({
            key: 'color',
            value: selectedOptions,
          });
        }}
        style={{ width: '100%' }}
      />
    </ProductFiltersContainer>
  );
};
