import { Flex, Steps, Typography } from 'antd';

import { UploadDecisionMakingUploadSheetStep } from '../../../components/decision-making-request/uploadDecisionMakingUploadSheetStep';
import { DecisionMakingRequestTable } from '../../../components/decision-making-request/decisionMakingRequestTable';
import { useAppDispatch, useAppSelector } from '../../../store/useAppDispatch';
import { decisionMakingRequestStateSelector } from '../../../store/decision-making-request/decisionMakingRequest';
import { DecisionMakingRequestTableHeader } from '../../../components/decision-making-request/decisionMakingRequestTableHeader';
import { useEffect } from 'react';
import { decisionMakingRequestActions } from '../../../store/decision-making-request/decisionMakingRequestActions';

const steps = [
  {
    title: 'Upload Sheet',
    content: <UploadDecisionMakingUploadSheetStep />,
  },
  {
    title: 'Modify Decision',
    content: <DecisionMakingRequestTable />,
    headerContent: <DecisionMakingRequestTableHeader />,
  },
  {
    title: 'Finalize',
    content: 'fin',
  },
];

export const DecisionMakingRequestCreationPage = () => {
  const { currentStep } = useAppSelector(decisionMakingRequestStateSelector);
  const items = steps.map((item) => ({ key: item.title, title: item.title }));
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(decisionMakingRequestActions.resetState());
    };
  }, []);

  return (
    <div>
      <Flex
        align={'center'}
        justify={'space-between'}
        style={{ marginBottom: 20 }}
      >
        <Typography.Title style={{ margin: 0 }} level={2}>
          Decision Making Request
        </Typography.Title>
        {steps[currentStep].headerContent}
      </Flex>
      <Steps style={{ marginBottom: 20 }} current={currentStep} items={items} />
      <div>{steps[currentStep].content}</div>
    </div>
  );
};
