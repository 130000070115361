/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Category,
  CategoryResponse,
  SaveSubCategoriesPayload,
  SubCategoryResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Categories<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name SaveCategory
   * @summary Save a category
   * @request POST:/categories
   */
  saveCategory = (data: Category, params: RequestParams = {}) =>
    this.request<CategoryResponse, any>({
      path: `/categories`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name FindAllCategories
   * @summary Find all categories
   * @request GET:/categories
   */
  findAllCategories = (params: RequestParams = {}) =>
    this.request<CategoryResponse[], any>({
      path: `/categories`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name SaveSubCategories
   * @summary Save SubCategories
   * @request POST:/categories/{categoryId}/subcategories
   */
  saveSubCategories = (
    categoryId: number,
    data: SaveSubCategoriesPayload,
    params: RequestParams = {}
  ) =>
    this.request<SubCategoryResponse[], any>({
      path: `/categories/${categoryId}/subcategories`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name UpdateCategory
   * @summary Update a category
   * @request PUT:/categories/{categoryId}
   */
  updateCategory = (
    categoryId: number,
    data: Category,
    params: RequestParams = {}
  ) =>
    this.request<CategoryResponse, any>({
      path: `/categories/${categoryId}`,
      method: 'PUT',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
