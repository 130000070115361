import { Card, Carousel, Image, Typography } from 'antd';
import { ProductDto } from '../../../types';
import styled from 'styled-components';

const imageRenderer = (imageUrl: string) => {
  return (
    <Image
      height={200}
      width={'100%'}
      src={imageUrl}
      fallback='https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/832px-No-Image-Placeholder.svg.png'
    />
  );
};

const StyledCard = styled(Card)`
  overflow: hidden;
  display: flex;
  .ant-card-body {
    width: 100%;
    padding: 0px;
  }
`;

export const ProductCard = ({ product }: { product: ProductDto }) => {
  const productImagesUrls =
    (product.productImageUrls
      ?.filter(Boolean)
      .map((img) => img.imageUrl) as string[]) ?? [];

  return (
    <StyledCard
      bordered={false}
      style={{ minWidth: 150, maxWidth: 250, maxHeight: 350, minHeight: 350 }}
    >
      {!!productImagesUrls.length && (
        <Carousel dotPosition={'bottom'}>
          {productImagesUrls.map((productImageUrl) => {
            return imageRenderer(productImageUrl);
          })}
        </Carousel>
      )}
      {!productImagesUrls.length && (
        <Image
          preview={false}
          height={200}
          width={'100%'}
          src='https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/832px-No-Image-Placeholder.svg.png'
        />
      )}
      <div style={{ padding: 15 }}>
        <Typography.Paragraph
          type={'secondary'}
          style={{ marginBottom: 0, fontWeight: 600 }}
        >
          {product?.category?.name}
        </Typography.Paragraph>
        <Typography.Paragraph style={{ fontWeight: 800, marginBottom: 0 }}>
          {product?.name}
        </Typography.Paragraph>
        <Typography.Paragraph type={'secondary'} style={{ marginBottom: 0 }}>
          {product?.color?.name}
        </Typography.Paragraph>
        <Typography.Paragraph>{product?.model?.name}</Typography.Paragraph>
      </div>
    </StyledCard>
  );
};
