/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TaskResponse } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class Excel<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name StartExcelInsertion
   * @summary Bulk inserting products by excel sheet
   * @request POST:/excel/start-inserion/{s3ItemId}
   */
  startExcelInsertion = (s3ItemId: number, params: RequestParams = {}) =>
    this.request<TaskResponse, any>({
      path: `/excel/start-inserion/${s3ItemId}`,
      method: 'POST',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name StartPublishingDecisionExcel
   * @summary Publish excel decision sheet
   * @request POST:/excel/start-decision/s3-items/{s3ItemId}/platforms/{platformId}
   */
  startPublishingDecisionExcel = (
    s3ItemId: number,
    platformId: number,
    params: RequestParams = {}
  ) =>
    this.request<TaskResponse, any>({
      path: `/excel/start-decision/s3-items/${s3ItemId}/platforms/${platformId}`,
      method: 'POST',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags excel
   * @name DownloadDictionaryInsertionTemplate
   * @summary Download excel dictionary template for bulk insertion
   * @request GET:/excel/download-dictionary-insertion-template
   */
  downloadDictionaryInsertionTemplate = (params: RequestParams = {}) =>
    this.request<File, any>({
      path: `/excel/download-dictionary-insertion-template`,
      method: 'GET',
      ...params,
    });
  /**
   * No description
   *
   * @tags excel
   * @name DownloadPlatformProductLinkingInsertionTemplate
   * @summary Download PlatformProduct excel linking for bulk insertion
   * @request GET:/excel/download-platform-linking-insertion-template
   */
  downloadPlatformProductLinkingInsertionTemplate = (
    params: RequestParams = {}
  ) =>
    this.request<File, any>({
      path: `/excel/download-platform-linking-insertion-template`,
      method: 'GET',
      ...params,
    });
  /**
   * No description
   *
   * @tags excel
   * @name DownloadDecisionTemplate
   * @summary Download Decision excel template to make decision request
   * @request GET:/excel/download-decision-template
   */
  downloadDecisionTemplate = (params: RequestParams = {}) =>
    this.request<File, any>({
      path: `/excel/download-decision-template`,
      method: 'GET',
      ...params,
    });
}
