import { useEffect, useState } from 'react';
import { DecisionMakingRequest } from '../../../core/decision-making-request/DecisionMakingRequest';
import { useParams } from 'react-router-dom';
import { decisionMakingRequestActions } from '../../../store/decision-making-request/decisionMakingRequestActions';
import { useAppDispatch } from '../../../store/useAppDispatch';
import { DecisionMakingRequestCreationPage } from '../creation-page/decisionMakingRequestCreationPage';
import { Spin } from 'antd';

export const DecisionMakingRequestDetailsPage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) {
      DecisionMakingRequest.getDecisionMakingRequestSheet(parseInt(id))
        .then(({ mappedData, sheetState }) => {
          dispatch(
            decisionMakingRequestActions.changeState({
              currentStep: 1,
              data: mappedData,
              sheetId: parseInt(id),
              sheetState,
            })
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <Spin spinning={loading}>
      <DecisionMakingRequestCreationPage />
    </Spin>
  );
};
