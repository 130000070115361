import { CategoryDto, EntityDto } from '../../types';
import { CategoryResponse } from '../../api/generated/data-contracts';
import { EntityCore } from '../entity/Entity';
import { ColumnFilterItem } from 'antd/es/table/interface';

export class CategoryCore {
  static mapCategoryResponseToCategoryDto(
    category: CategoryResponse
  ): CategoryDto {
    return {
      name: category.name as string,
      id: category.id as number,
      subCategories: category?.subCategories?.map(
        EntityCore.mapEntityResponseToEntityDto
      ) as EntityDto[],
    };
  }

  static getAllSubCategories(categories: CategoryDto[]): EntityDto[] {
    return categories
      ?.filter((cat) => !!cat.subCategories)
      ?.reduce((accumulator, currentValue) => {
        if (currentValue.subCategories) {
          accumulator.push(...currentValue.subCategories);
        }
        return accumulator;
      }, [] as EntityDto[]);
  }

  static mapSubCategoryToTableFilters(
    categories: CategoryDto[]
  ): ColumnFilterItem[] {
    const categoriesWithSubCategories = categories?.filter?.(
      (cat) => !!cat.subCategories
    );

    return categoriesWithSubCategories?.map?.((cat) => {
      return {
        ...EntityCore.mapEntityDtoItemToTableFilter(cat),
        children: EntityCore.mapEntityDtoItemsToTableFilters(
          cat.subCategories ?? []
        ),
      };
    });
  }
}
