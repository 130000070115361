import { Button, Input, Popover, Space, InputRef, Select } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { useEffect, useRef, useState } from 'react';
import { AddPopupContainerProps } from '../entity-table/entityTable.types';
import { CategoryDto, SelectLabelValue } from '../../types';
import { mapToSelectOptionsType } from '../../utils';
import { isEmpty } from 'lodash';
import { useEditCategoryMutation } from '../../store/entityRtk/apiSlice';

interface Props extends AddPopupContainerProps {
  initialRecord: CategoryDto;
}

export const CategoryEditPopover = ({ entityName, initialRecord }: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  const Content = () => {
    const [category, setCategory] = useState<CategoryDto>(initialRecord);
    const inputRef = useRef<InputRef>(null);
    const [editCategory, { isLoading }] = useEditCategoryMutation();

    useEffect(() => {
      inputRef.current?.focus();
    }, []);

    const editEntity = () => {
      editCategory({
        name: category.name,
        subCategories: category.subCategories,
        id: category.id,
      });

      setOpen(false);
    };

    return (
      <Space
        style={{ maxWidth: 200, width: 200 }}
        size={'small'}
        direction={'vertical'}
      >
        <Input
          ref={inputRef}
          value={category.name}
          onPressEnter={editEntity}
          onChange={(e) => setCategory({ ...category, name: e.target.value })}
          placeholder={`Enter ${entityName} Name`}
        />
        <Select
          open={false}
          value={mapToSelectOptionsType(
            category.subCategories ?? [],
            'name',
            'name'
          )}
          onChange={(subCategories) => {
            setCategory({
              ...category,
              subCategories: subCategories.map((subc: SelectLabelValue) => ({
                name: subc?.value?.toString?.(),
                id: subc.value as number,
              })),
            });
          }}
          labelInValue
          placeholder='Add Subcategory'
          style={{ width: '100%' }}
          mode={'tags'}
        />
        <Button
          disabled={isEmpty(category.name)}
          loading={isLoading}
          onClick={editEntity}
          type={'primary'}
        >
          Edit
        </Button>
      </Space>
    );
  };

  return (
    <Popover
      style={{ maxWidth: 200, width: 200 }}
      open={open}
      placement='leftTop'
      content={<Content />}
      onOpenChange={(visible) => setOpen(visible)}
      title={`Adding New ${entityName}`}
      trigger='click'
    >
      <Button
        onClick={() => setOpen(true)}
        type={'link'}
        icon={<EditOutlined />}
      />
    </Popover>
  );
};
