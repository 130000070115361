import { DecisionMakingRequestInitialState } from './decisionMakingRequest';

export const addDirtyRecordDecisionMakingRequest = (
  state: DecisionMakingRequestInitialState,
  payload: {
    decisionIndex: number;
    productIndex: number;
    decidedWarehouseShipmentIndex?: number;
    decidedShipmentQuantity?: number;
    willBePrepared?: boolean;
  }
) => {
  const {
    decisionIndex,
    productIndex,
    decidedWarehouseShipmentIndex,
    decidedShipmentQuantity,
    willBePrepared,
  } = payload;

  const decisionProductId =
    state.data[productIndex].decisions[decisionIndex].decisionProductId;

  /// CASE 1: USER CHANGED THE SHIPMENT
  if (
    decidedWarehouseShipmentIndex !== undefined &&
    decidedShipmentQuantity! !== undefined
  ) {
    const warehouseId =
      state.data[productIndex].decisions[decisionIndex]
        .decidedWarehouseShipments[decidedWarehouseShipmentIndex].warehouseId;

    if (!state.dirtyRecords[decisionProductId]) {
      state.dirtyRecords[decisionProductId] = { decidedWarehouseShipments: {} };
    }

    (state.dirtyRecords[decisionProductId] as any).decidedWarehouseShipments[
      warehouseId
    ] = decidedShipmentQuantity;
  } else {
    /// CASE 2: USER CHANGED WILL BE PREPARED
    if (state.dirtyRecords[decisionProductId]) {
      state.dirtyRecords[decisionProductId].willBePrepared = willBePrepared;
    } else {
      state.dirtyRecords[decisionProductId] = {
        willBePrepared: willBePrepared,
      };
    }
  }
};
