import { Button, Form, FormItemProps, FormProps } from 'antd';
import { cloneElement, isValidElement, ReactNode } from 'react';
import { formDefaultProperties } from './formDefaultProperties';
import { FormLayout } from 'antd/lib/form/Form';

export const InventoriaForm = <T,>({
  children,
  loading,
  hideSubmit,
  ...rest
}: FormProps<T> & {
  children: ReactNode;
  loading?: boolean;
  hideSubmit?: boolean;
}) => {
  const style = rest.style ?? formDefaultProperties.styles;
  const layout = rest.layout ?? formDefaultProperties.layout;

  return (
    <Form {...rest} layout={layout as FormLayout}>
      <div style={style}>{children}</div>
      {!hideSubmit && (
        <Button loading={loading} type={'primary'} htmlType={'submit'}>
          Submit
        </Button>
      )}
    </Form>
  );
};

const FormItem = <T,>(props: FormItemProps<T>) => {
  let childrenWithCorrectPlaceholder = props.children;

  if (isValidElement(props.children) && !props.children.props.placeholder) {
    childrenWithCorrectPlaceholder = cloneElement(props.children, {
      placeholder: `Enter ${props.label?.toString()}`,
      ...props.children.props,
    });
  }

  return <Form.Item {...props}>{childrenWithCorrectPlaceholder}</Form.Item>;
};

InventoriaForm.Item = FormItem;
InventoriaForm.useForm = Form.useForm;
InventoriaForm.useWatch = Form.useWatch;
