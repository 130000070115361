import {
  InventarioColumnType,
  TableTypes,
} from '../../../components/table/inventario.types';
import {
  DecisionMakingRequestListingPageGridRow,
  DecisionMakingRequestStateTypeEnum,
} from '../../../types';
import { DecisionMakingRequestStateTag } from '../../../components/decision-making-request/decisionMakingRequestStateTag';
import { InventoriaRoutes } from '../../../routes/routes';
import { Link } from 'react-router-dom';

export const useGetDecisionMakingRequestPageColumns =
  (): InventarioColumnType<DecisionMakingRequestListingPageGridRow>[] => {
    return [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 90,
        type: [TableTypes.NUMBER_FILTER],
      },
      {
        title: 'Name',
        dataIndex: 'sheetName',
        type: [TableTypes.TEXT_FILTER],
        render: (_, record) => {
          return (
            <Link
              to={`${InventoriaRoutes.DECISION_MAKING_REQUEST_DETAILS.replace(':id', record.id?.toString?.())}`}
            >
              {record.sheetName}
            </Link>
          );
        },
      },
      {
        title: 'State',
        width: 200,
        dataIndex: 'state',
        filters: [
          {
            text: 'Pending',
            value: DecisionMakingRequestStateTypeEnum.PENDING,
          },
          {
            text: 'Confirmed',
            value: DecisionMakingRequestStateTypeEnum.CONFIRMED,
          },
        ],
        render: (_, record) => (
          <DecisionMakingRequestStateTag state={record.state} />
        ),
      },
    ];
  };
