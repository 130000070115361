import { Table, TablePaginationConfig, TableProps } from 'antd';
import { InventarioProps } from './inventario.types';
import { mapInventarioColumnsToTableColumns } from './invernatrio.utils';
import { AnyObject } from 'antd/es/_util/type';
import { mapAntdTableFiltersToRSQL } from '../../utils';
import { useGetServerTablePagination } from './hooks/useGetServerTablePagination';
import { useEffect } from 'react';
import { useGetServerSideState } from './hooks/useGetServerSideState';
import { Config } from '../../config';

export const InventarioTable = <T extends AnyObject>(
  props: InventarioProps<T>
) => {
  const columnsMapped = mapInventarioColumnsToTableColumns<T>(
    props.columns ?? [],
    props
  );

  const { total, currentPage, setData, data, setTotal, setCurrentPage } =
    useGetServerTablePagination<T>();

  const { rsqlFilters, setRsqlFilters } = useGetServerSideState();

  const serverTableSuccessCallback = () => {
    props
      ?.serverSideApiFunction?.({
        queryOptions: {
          filter: rsqlFilters,
          pageSize: (props?.pagination as TablePaginationConfig)?.pageSize,
          pageNumber: currentPage - 1,
        },
      })
      .then((resp) => {
        const data = resp.data;
        setData(data.content);
        const total =
          resp.data.paginationInfo.pageSize *
          resp.data.paginationInfo.numberOfPages;

        setTotal(total);
      })
      .catch(() => {});
  };

  const handleServerSideTableChange: TableProps['onChange'] = (
    pagination,
    filters
  ) => {
    setRsqlFilters(mapAntdTableFiltersToRSQL(filters));
    setCurrentPage(pagination.current ?? 1);
  };

  useEffect(() => {
    if (props.serverSideTable) {
      serverTableSuccessCallback();
    }
  }, [rsqlFilters, currentPage]);

  return (
    <>
      <Table
        bordered={true}
        {...props}
        dataSource={props.dataSource ?? data}
        columns={columnsMapped}
        pagination={
          props.pagination === false
            ? false
            : {
                ...Config.pagination,
                showTotal: (total) => `Total ${total} items`,
                current: currentPage,
                total,
                ...props.pagination,
              }
        }
        onChange={(...args) => {
          if (props.serverSideTable) {
            handleServerSideTableChange?.(...args);
          } else if (props.pagination !== false) {
            setCurrentPage(args?.[0]?.current ?? 0);
          }
          props?.onChange?.(...args);
        }}
      />
    </>
  );
};
