/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  QueryOptions,
  Warehouse,
  WarehousePageResponse,
  WarehouseResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Warehouses<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name SaveWarehouse
   * @summary Save a warehouse
   * @request POST:/warehouses
   */
  saveWarehouse = (data: Warehouse, params: RequestParams = {}) =>
    this.request<WarehouseResponse, any>({
      path: `/warehouses`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name FindAllWarehouses
   * @summary Find all warehouses
   * @request GET:/warehouses
   */
  findAllWarehouses = (
    query?: {
      /** Paging, sorting, and filtration parameters */
      queryOptions?: QueryOptions;
    },
    params: RequestParams = {}
  ) =>
    this.request<WarehousePageResponse, any>({
      path: `/warehouses`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name UpdateWarehouse
   * @summary Update a warehouse
   * @request PUT:/warehouses/{warehouseId}
   */
  updateWarehouse = (
    warehouseId: number,
    data: Warehouse,
    params: RequestParams = {}
  ) =>
    this.request<WarehouseResponse, any>({
      path: `/warehouses/${warehouseId}`,
      method: 'PUT',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
