import {
  Button,
  Flex,
  Pagination,
  Segmented,
  Space,
  Table,
  Typography,
} from 'antd';
import { NavLink } from 'react-router-dom';
import {
  AppstoreOutlined,
  BarsOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useState } from 'react';
import { ProductGrid } from '../../../components/product/product-grid/productGrid';
import { useGetProductListingColumns } from './hooks/useGetProductListingColumns';
import { useGetProductsData } from './hooks/useGetProductsData';
import { Config } from '../../../config';

enum ProductListingViewEnum {
  GRID = 'GRID',
  TABLE = 'TABLE',
}

export const ProductsListingPage = () => {
  const [currentView, setCurrentView] = useState<ProductListingViewEnum>(
    ProductListingViewEnum.TABLE
  );
  const { products, onChangeFilterTable, filters, total, current } =
    useGetProductsData();
  const columns = useGetProductListingColumns(filters);

  const switchButtonRenderer = () => {
    return (
      <>
        <Segmented
          onChange={(value) => {
            setCurrentView(value as ProductListingViewEnum);
          }}
          options={[
            { value: 'TABLE', icon: <BarsOutlined /> },
            { value: 'GRID', icon: <AppstoreOutlined /> },
          ]}
        />
      </>
    );
  };

  return (
    <Space style={{ width: '100%' }} size='middle' direction='vertical'>
      <Flex gap={5} justify={'space-between'}>
        <Typography.Title>Products Listing page</Typography.Title>
        <Flex align={'center'} gap={5}>
          <NavLink
            style={{ textAlign: 'right', display: 'block' }}
            to={'/products/add'}
          >
            <Button type={'primary'} icon={<PlusOutlined />}>
              Add Product
            </Button>
          </NavLink>
          {switchButtonRenderer()}
        </Flex>
      </Flex>
      {currentView === ProductListingViewEnum.TABLE && (
        <Table
          tableLayout={'fixed'}
          bordered
          rowKey={(record) => record.id ?? ''}
          columns={columns}
          pagination={false}
          dataSource={products}
          onChange={onChangeFilterTable}
          scroll={{
            scrollToFirstRowOnChange: false,
            y: 'calc(100vh - 400px)',
            x: '900px',
          }}
        />
      )}
      {currentView === ProductListingViewEnum.GRID && (
        <ProductGrid products={products ?? []} />
      )}
      <Flex justify={'flex-end'}>
        <Pagination
          showTotal={(total) => `Total ${total} items`}
          total={total}
          pageSize={Config.pagination.pageSize}
          onChange={(page) => {
            onChangeFilterTable(
              {
                total: total,
                current: page,
              },
              filters
            );
          }}
          current={current}
        />
      </Flex>
    </Space>
  );
};
