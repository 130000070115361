import { Flex, Space, Typography } from 'antd';
import { EntityDto } from '../../types';
import { DefaultEntityAddPopover } from './defaultEntityAddPopover';
import DefaultEntityEditPopover from './defaultEntityEditPopover';
import { InventarioTable } from '../table/inventarioTable';
import {
  InventarioColumnType,
  InventarioProps,
  TableTypes,
} from '../table/inventario.types';

interface Props {
  entityName: string;
  addApi: (newItemName: string) => void;
  editApi: (entity: EntityDto) => void;
  loading: boolean;
  dataSource?: EntityDto[];
  serverSideTable?: boolean;
  serverSideApiFunction?: InventarioProps<EntityDto>['serverSideApiFunction'];
}

export const EntityTable = (props: Props) => {
  const columns: InventarioColumnType<EntityDto>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 90,
      type: [TableTypes.NUMBER_FILTER],
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      type: [TableTypes.TEXT_FILTER],
    },
    {
      dataIndex: 'actions',
      width: 100,
      title: 'Actions',
      key: 'actions',
      render: (text: string, record: EntityDto) => (
        <DefaultEntityEditPopover
          loading={props.loading}
          entityName={props.entityName}
          editApi={props.editApi}
          record={record}
        />
      ),
    },
  ];

  return (
    <Space direction='vertical' size={'small'} style={{ width: '100%' }}>
      <Flex align={'center'} justify={'space-between'}>
        <Typography.Title>{props.entityName} Listing page</Typography.Title>
        <DefaultEntityAddPopover
          loading={props.loading}
          entityName={props.entityName}
          addApi={props.addApi}
        />
      </Flex>
      <InventarioTable
        tableLayout={'fixed'}
        serverSideTable={props.serverSideTable}
        scroll={{ y: 'calc(100vh - 400px)' }}
        rowKey={(record: EntityDto) => record?.id?.toString?.() ?? ''}
        loading={props.loading}
        columns={columns}
        dataSource={props.dataSource}
        serverSideApiFunction={props.serverSideApiFunction}
      />
    </Space>
  );
};
