import { Button, Input, Popover, Space, Select, InputRef } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { useEffect, useRef, useState } from 'react';
import { AddPopupContainerProps } from '../entity-table/entityTable.types';
import { useAddCategoryMutation } from '../../store/entityRtk/apiSlice';

export const CategoryAddPopover = ({ entityName }: AddPopupContainerProps) => {
  const Content = () => {
    const [categoryName, setCategoryName] = useState('');
    const [subCategories, setSubCategories] = useState([]);
    const inputRef = useRef<InputRef>(null);
    const [addCategory, { isLoading }] = useAddCategoryMutation();

    useEffect(() => {
      inputRef.current?.focus();
    }, []);

    const saveEntity = () => {
      addCategory({
        name: categoryName,
        subCategories,
      });
    };

    return (
      <Space
        style={{ maxWidth: 200, width: 200 }}
        size={'small'}
        direction={'vertical'}
      >
        <Input
          ref={inputRef}
          value={categoryName}
          onPressEnter={saveEntity}
          onChange={(e) => setCategoryName(e.target.value)}
          placeholder={`Enter ${entityName} Name`}
        />
        <Select
          open={false}
          value={subCategories}
          onChange={(value) => setSubCategories(value)}
          placeholder='Add Subcategory'
          style={{ width: '100%' }}
          mode={'tags'}
        />
        <Button
          disabled={categoryName === ''}
          loading={isLoading}
          onClick={saveEntity}
          type={'primary'}
        >
          Add
        </Button>
      </Space>
    );
  };

  return (
    <Popover
      placement='leftTop'
      content={<Content />}
      title={`Adding New ${entityName}`}
      trigger='click'
    >
      <Button type={'primary'} icon={<PlusOutlined />}>
        Add New
      </Button>
    </Popover>
  );
};
