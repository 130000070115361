import { Button, Space, TableColumnsType, Tag, Typography } from 'antd';
import { ShipmentSummaryResponse } from '../../api/generated/data-contracts';
import { api } from '../../store/entityRtk/apiSlice';
import { InventarioTable } from '../../components/table/inventarioTable';
import React from 'react';
import { useSearchParamTablePagination } from '../../hooks/useSearchParamTablePagination';
import { NavLink } from 'react-router-dom';
import { InventoriaRoutes } from '../../routes/routes';
import { LinkOutlined } from '@ant-design/icons';
import { getStatusColor, ShipmentStatus } from './shipmentStatus';

export const ShipmentsListingPage = () => {
  const { pageNumber, pageSize, filter, handleTableChange } =
    useSearchParamTablePagination();
  const { data, isFetching } = api.useGetAllShipmentsQuery({
    pageNumber,
    pageSize,
    filter,
  });

  const columns: TableColumnsType<ShipmentSummaryResponse> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '10%',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: ShipmentStatus.IDLE, value: ShipmentStatus.IDLE },
        { text: ShipmentStatus.PREPARING, value: ShipmentStatus.PREPARING },
        { text: ShipmentStatus.PREPARED, value: ShipmentStatus.PREPARED },
      ],
      filteredValue: filter ? [filter] : [],
      render: (status) => <Tag color={getStatusColor(status)}>{status}</Tag>,
      width: '10%',
    },
    {
      title: 'Creation Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => isoToHumanReadable(createdAt),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, { id }) => (
        <NavLink
          to={InventoriaRoutes.SHIPMENT.replace(':shipmentId', id.toString())}
        >
          <Button type={'link'} icon={<LinkOutlined />} />
        </NavLink>
      ),
      width: '10%',
    },
  ];

  return (
    <Space direction='vertical' size={'small'} style={{ width: '100%' }}>
      <Typography.Title>Shipments</Typography.Title>
      <InventarioTable
        dataSource={data?.content}
        columns={columns}
        loading={isFetching}
        rowKey={(record) => record.id}
        pagination={{
          current: pageNumber + 1,
          pageSize: pageSize,
          total: data?.paginationInfo.totalNumberOfElements,
          showQuickJumper: true,
          showSizeChanger: true,
          hideOnSinglePage: true,
        }}
        onChange={handleTableChange}
        scroll={{ y: 500 }}
      />
    </Space>
  );
};

function isoToHumanReadable(isoString: string) {
  const date = new Date(isoString);
  return date.toLocaleString('en-US', {
    weekday: 'long', // "Monday"
    year: 'numeric', // "2023"
    month: 'long', // "June"
    day: 'numeric', // "23"
    hour: '2-digit', // "01"
    minute: '2-digit', // "45"
    second: '2-digit', // "30"
    hour12: true, // 12-hour format
    timeZone: 'UTC',
  });
}
