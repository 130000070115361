import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DecisionMakingRequestGridRow,
  SelectLabelValue,
  PlatformProductDecisionTableGridRow,
  DecisionMakingRequestDirtyRecordMap,
  DecisionMakingRequestStateTypeEnum,
} from '../../types';
import { merge } from 'lodash';
import { RootState } from '../useAppDispatch';
import { DecisionMakingRequest } from '../../core/decision-making-request/DecisionMakingRequest';
import { addDirtyRecordDecisionMakingRequest } from './utils';

export interface DecisionMakingRequestInitialState {
  nextEnabled: boolean;
  previousEnabled: boolean;
  currentPlatform: SelectLabelValue | null;
  isSheetUploaded: boolean;
  currentStep: number;
  data: DecisionMakingRequestGridRow[];
  sheetState: DecisionMakingRequestStateTypeEnum;
  sheetId: number;
  dirtyRecords: DecisionMakingRequestDirtyRecordMap;
}

export const decisionMakingRequestInitialState = {
  nextEnabled: false,
  previousEnabled: false,
  currentPlatform: null,
  isSheetUploaded: false,
  sheetState: DecisionMakingRequestStateTypeEnum.PENDING,
  currentStep: 0,
  data: [] as DecisionMakingRequestGridRow[],
  sheetId: 0,
  dirtyRecords: {} as DecisionMakingRequestDirtyRecordMap,
};

export const decisionMakingRequestStateSelector = (state: RootState) =>
  state.decisionMakingRequestSlice;

export const decisionMakingRequestSlice = createSlice({
  name: 'decisionMakingRequestSlice',
  initialState: decisionMakingRequestInitialState,
  reducers: {
    changeState: (
      state,
      action: PayloadAction<Partial<DecisionMakingRequestInitialState>>
    ) => {
      state = merge(state, action.payload);

      return state;
    },
    changeWarehouseSubTable: (
      state,
      action: PayloadAction<{
        productId: number;
        data: PlatformProductDecisionTableGridRow;
      }>
    ) => {
      const recordId = action.payload.productId;
      const platformProductData = action.payload.data;

      const productIndex = state.data.findIndex(
        (item) => item.productId === recordId
      );

      const platFormProductIndex = state.data[productIndex].decisions.findIndex(
        (platformProduct) =>
          platformProduct.platformProductId ===
          platformProductData.platformProductId
      );

      state.data[productIndex].decisions[platFormProductIndex] =
        platformProductData;
    },
    updateShipmentQuantity: (
      state,
      action: PayloadAction<{
        productIndex: number;
        decisionIndex: number;
        decidedWarehouseShipmentIndex: number;
        decidedShipmentQuantity: number;
      }>
    ) => {
      const {
        productIndex,
        decisionIndex,
        decidedWarehouseShipmentIndex,
        decidedShipmentQuantity,
      } = action.payload;

      /* ------------------ Updating warehouse decided shipment per warehouse & Total per product---------------------------- */

      /// [Get] shipment old value
      const decidedShipmentOldValue =
        state.data[productIndex].decisions[decisionIndex]
          .decidedWarehouseShipments[decidedWarehouseShipmentIndex]
          .decidedShipmentQuantity;

      // [Get] change factor, for example how much it changed if it raised by 3 then the factor is 3 add to total 3 if decreased by 3 then the factor is -3
      const totalShipmentFactor =
        decidedShipmentQuantity - decidedShipmentOldValue;

      // [Get] warehouse stocks
      const warehouseStocks =
        state.data[productIndex].decisions[decisionIndex]
          .decidedWarehouseShipments[decidedWarehouseShipmentIndex]
          .warehouseStocks;

      // [Get] "willBePrepared"
      const willBePrepared =
        state.data[productIndex].decisions[decisionIndex].willBePrepared;

      // [Set] the new decided shipment
      state.data[productIndex].decisions[
        decisionIndex
      ].decidedWarehouseShipments[
        decidedWarehouseShipmentIndex
      ].decidedShipmentQuantity = decidedShipmentQuantity;

      // [Set] the factor to the total
      state.data[productIndex].totalShipmentQuantity += totalShipmentFactor;

      /* ------------------ [END]---------------------------- */

      /* ------------------ Updating purchase quantity per warehouse and total ---------------------------- */
      // Using same method as before we will get the factor and update the total of the purchase with that factor

      const purchaseQuantityOldValue =
        state.data[productIndex].decisions[decisionIndex]
          .decidedWarehouseShipments[decidedWarehouseShipmentIndex]
          .purchaseQuantity;

      const calculatedPurchaseQuantity =
        DecisionMakingRequest.calculatePurchaseQuantity(
          warehouseStocks,
          decidedShipmentQuantity,
          willBePrepared
        );

      const purchaseQuantityFactor =
        calculatedPurchaseQuantity - purchaseQuantityOldValue;

      // [Set] the purchase quantity after changing the shipment, purchase should be the decided minus the stocks in the same warehouse
      state.data[productIndex].decisions[
        decisionIndex
      ].decidedWarehouseShipments[
        decidedWarehouseShipmentIndex
      ].purchaseQuantity = calculatedPurchaseQuantity;

      // [Set] the factor to the total
      state.data[productIndex].totalPurchaseQuantity += purchaseQuantityFactor;
      /* ------------------ END ---------------------------- */

      addDirtyRecordDecisionMakingRequest(state, {
        decisionIndex,
        productIndex,
        decidedWarehouseShipmentIndex,
        decidedShipmentQuantity,
      });
    },
    updateWillBePrepared: (
      state,
      action: PayloadAction<{
        decisionIndex: number;
        willBePrepared: boolean;
        productIndex: number;
      }>
    ) => {
      const { productIndex, decisionIndex, willBePrepared } = action.payload;

      state.data[productIndex].decisions[decisionIndex].willBePrepared =
        willBePrepared;

      DecisionMakingRequest.calculateTotalPurchaseQuantityForWarehouseProducts(
        state.data[productIndex].decisions[decisionIndex]
      );

      state.data[productIndex].totalPurchaseQuantity =
        DecisionMakingRequest.calculateTotalPurchaseQuantityForProduct(
          state.data[productIndex].decisions
        );

      addDirtyRecordDecisionMakingRequest(state, {
        decisionIndex,
        productIndex,
        willBePrepared,
      });

      return state;
    },
    updateSheetState: (
      state,
      action: PayloadAction<DecisionMakingRequestStateTypeEnum>
    ) => {
      state.sheetState = action.payload;
    },
    resetDirtyRecord: (state) => {
      state.dirtyRecords = {};
    },
    resetState: () => {
      return decisionMakingRequestInitialState;
    },
  },
});
