/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Color,
  ColorPageResponse,
  ColorResponse,
  QueryOptions,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Colors<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name SaveColor
   * @summary Save a color
   * @request POST:/colors
   */
  saveColor = (data: Color, params: RequestParams = {}) =>
    this.request<ColorResponse, any>({
      path: `/colors`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name FindAllColors
   * @summary Find all colors
   * @request GET:/colors
   */
  findAllColors = (
    query?: {
      /** Paging, sorting, and filtration parameters */
      queryOptions?: QueryOptions;
    },
    params: RequestParams = {}
  ) =>
    this.request<ColorPageResponse, any>({
      path: `/colors`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name UpdateColor
   * @summary Update color
   * @request PUT:/colors/{colorId}
   */
  updateColor = (colorId: number, data: Color, params: RequestParams = {}) =>
    this.request<ColorResponse, any>({
      path: `/colors/${colorId}`,
      method: 'PUT',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
