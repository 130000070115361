import { EntityDto, SelectLabelValue } from '../../types';
import { ColumnFilterItem } from 'antd/es/table/interface';

export class EntityCore {
  static mapEntityResponseToEntityDto<T extends { name?: string; id?: number }>(
    entity?: T
  ): EntityDto | undefined {
    if (!entity) {
      return undefined;
    }
    return {
      name: entity.name as string,
      id: entity.id as number,
      ...entity,
    };
  }

  static mapEntityDtoItemsToTableFilters(
    entityDto: EntityDto[]
  ): ColumnFilterItem[] {
    return entityDto?.map?.(this.mapEntityDtoItemToTableFilter);
  }

  static mapEntityDtoItemToTableFilter(entityDto: EntityDto): ColumnFilterItem {
    return {
      text: entityDto.name,
      value: entityDto.id,
    };
  }

  static mapEntityDtoItemsToLabelValueSelectValue(
    entityDto: EntityDto
  ): SelectLabelValue {
    return {
      label: entityDto?.name,
      value: entityDto?.id,
    };
  }
}
