/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PlatformProduct,
  PlatformProductPageResponse,
  PlatformProductResponse,
  QueryOptions,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class PlatformsProducts<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name SavePlatformProduct
   * @summary Save a platform product
   * @request POST:/platforms-products
   */
  savePlatformProduct = (data: PlatformProduct, params: RequestParams = {}) =>
    this.request<PlatformProductResponse, any>({
      path: `/platforms-products`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name FindAllPlatformsProducts
   * @summary Find all platformsProducts
   * @request GET:/platforms-products
   */
  findAllPlatformsProducts = (
    query?: {
      /** Paging, sorting, and filtration parameters */
      queryOptions?: QueryOptions;
    },
    params: RequestParams = {}
  ) =>
    this.request<PlatformProductPageResponse, any>({
      path: `/platforms-products`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name UpdatePlatformProduct
   * @summary Update a platform product
   * @request PUT:/platforms-products/{productPlatformId}
   */
  updatePlatformProduct = (
    productPlatformId: number,
    data: PlatformProduct,
    params: RequestParams = {}
  ) =>
    this.request<PlatformProductResponse, any>({
      path: `/platforms-products/${productPlatformId}`,
      method: 'PUT',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
