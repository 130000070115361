import { FormProps, Input, notification, Typography } from 'antd';
import { InventoriaForm } from '../../components/form/form';
import { useAddWarehouseMutation } from '../../store/entityRtk/apiSlice';
import { Warehouse } from '../../api/generated/data-contracts';

export const WarehousesAddPage = () => {
  const [addWarehouse, { isLoading }] = useAddWarehouseMutation();
  const [form] = InventoriaForm.useForm<Warehouse>();

  const saveWarehouse: FormProps<Warehouse>['onFinish'] = async (warehouse) => {
    try {
      const response = await addWarehouse(warehouse).unwrap();
      form.resetFields();
      notification.success({
        message: `Warehouse '${response.name}' added successfully!`,
      });
    } catch (e) {
      // Error handled by Axios interceptor
    }
  };

  return (
    <div>
      <Typography.Title level={2}>Add Warehouse</Typography.Title>
      <InventoriaForm<Warehouse>
        form={form}
        loading={isLoading}
        onFinish={saveWarehouse}
      >
        <InventoriaForm.Item<Warehouse>
          required
          name={'name'}
          label={'Name'}
          rules={[
            { required: true, message: 'Warehouse name is required' },
            {
              max: 200,
              message: 'Warehouse name cannot exceed 200 characters',
            },
          ]}
        >
          <Input placeholder='Warehouse name' />
        </InventoriaForm.Item>
        <InventoriaForm.Item<Warehouse>
          required
          name={'address'}
          label={'Address'}
          rules={[
            { required: true, message: 'Warehouse address is required' },
            {
              max: 200,
              message: 'Warehouse address cannot exceed 200 characters',
            },
          ]}
        >
          <Input placeholder='Warehouse address' />
        </InventoriaForm.Item>
      </InventoriaForm>
    </div>
  );
};
