import { Button, Flex } from 'antd';
import { CheckOutlined, SaveFilled } from '@ant-design/icons';
import { inventoriaApi } from '../../api/inventoria-api-factory/inventoriaApiFactory';
import { useAppDispatch, useAppSelector } from '../../store/useAppDispatch';
import {
  DecisionMakingRequestInitialState,
  decisionMakingRequestStateSelector,
} from '../../store/decision-making-request/decisionMakingRequest';

import { DecisionMakingRequest } from '../../core/decision-making-request/DecisionMakingRequest';
import { useState } from 'react';
import { DecisionMakingRequestStateTypeEnum } from '../../types';
import { decisionMakingRequestActions } from '../../store/decision-making-request/decisionMakingRequestActions';
import { isEmpty } from 'lodash';

export const DecisionMakingRequestTableHeader = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const decisionMakingState: DecisionMakingRequestInitialState = useAppSelector(
    decisionMakingRequestStateSelector
  );

  const saveUpdatedData = async () => {
    const payload =
      DecisionMakingRequest.mapDecisionMakingRequestDirtyRecordMapToUpdateDecidedShipmentPayload(
        decisionMakingState.dirtyRecords
      );

    await inventoriaApi.decisionRequest.updateDecidedShipment(
      decisionMakingState.sheetId,
      payload
    );
  };

  const onSaveForLaterClick = async () => {
    setLoading(true);
    await saveUpdatedData();
    dispatch(decisionMakingRequestActions.resetDirtyRecord());
    setLoading(false);
  };

  const onConfirmClick = async () => {
    setLoading(true);
    await saveUpdatedData();
    await inventoriaApi.decisionRequest.confirmDecision(
      decisionMakingState.sheetId
    );
    dispatch(
      decisionMakingRequestActions.updateSheetState(
        DecisionMakingRequestStateTypeEnum.CONFIRMED
      )
    );
    setLoading(false);
  };

  return (
    <Flex gap={5}>
      <Button
        disabled={
          decisionMakingState.sheetState ===
            DecisionMakingRequestStateTypeEnum.CONFIRMED ||
          isEmpty(decisionMakingState.dirtyRecords)
        }
        loading={loading}
        onClick={onSaveForLaterClick}
        icon={<SaveFilled />}
      >
        Save for later
      </Button>
      <Button
        disabled={
          decisionMakingState.sheetState ===
          DecisionMakingRequestStateTypeEnum.CONFIRMED
        }
        loading={loading}
        onClick={onConfirmClick}
        type={'primary'}
        icon={<CheckOutlined />}
      >
        Confirm
      </Button>
    </Flex>
  );
};
