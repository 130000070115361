import { Button, TableColumnsType, Tag } from 'antd';
import { CategoryDto, EntityDto, ProductDto } from '../../../../types';
import { getStringFilterProp } from '../../../../components/table/table-search/stringSearchProps';
import { EntityCore } from '../../../../core/entity/Entity';
import { CategoryCore } from '../../../../core/category/Category';
import { NavLink } from 'react-router-dom';
import { InventoriaRoutes } from '../../../../routes/routes';
import { EditOutlined, LinkOutlined } from '@ant-design/icons';
import { ProductListingPageFilters } from '../../../../store/types';
import { mapDefaultTableFiltersToColumnFilters } from '../../../../utils';
import {
  useGetAllCategoriesQuery,
  useGetAllColorsQuery,
  useGetAllModelsQuery,
} from '../../../../store/entityRtk/apiSlice';
import { ManageProductStock } from '../../manage-product-stock/manageProductStock';

export const useGetProductListingColumns = (
  defaultFilters?: ProductListingPageFilters
) => {
  const { data: colors } = useGetAllColorsQuery();
  const { data: categories } = useGetAllCategoriesQuery();
  const { data: models } = useGetAllModelsQuery();

  const columns: TableColumnsType<ProductDto> = [
    {
      title: 'Product Name',
      key: 'name',
      dataIndex: 'name',
      ...getStringFilterProp<ProductDto>({
        dataIndex: 'name',
      }),
      defaultFilteredValue: defaultFilters?.name,
    },
    {
      title: 'Internal SKU',
      dataIndex: 'internalProductCodeSku',
      key: 'internalProductCodeSku',
      ...getStringFilterProp<ProductDto>({
        dataIndex: 'internalProductCodeSku',
      }),
      defaultFilteredValue: defaultFilters?.internalProductCodeSku,
    },
    {
      title: 'Product Description',
      dataIndex: 'productDescription',
      key: 'productDescription',
      ...getStringFilterProp<ProductDto>({
        dataIndex: 'name',
      }),
      defaultFilteredValue: defaultFilters?.productDescription,
    },
    {
      title: 'Model',
      dataIndex: 'model',
      render: (model) => <span>{model.name}</span>,
      key: 'model',
      filters: EntityCore.mapEntityDtoItemsToTableFilters(
        models as EntityDto[]
      ),
      filterSearch: true,
      defaultFilteredValue: mapDefaultTableFiltersToColumnFilters(
        defaultFilters?.model as number[]
      ),
      filterMode: 'menu',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      render: (category) => (
        <Tag color={'blue'}>{category?.name ?? 'No Category'}</Tag>
      ),
      filters: EntityCore.mapEntityDtoItemsToTableFilters(
        categories as CategoryDto[]
      ),
      filterSearch: true,
      defaultFilteredValue: mapDefaultTableFiltersToColumnFilters(
        defaultFilters?.category as number[]
      ),
    },
    {
      title: 'Subcategory',
      dataIndex: 'subCategory',
      key: 'subCategory',
      filters: CategoryCore?.mapSubCategoryToTableFilters?.(
        categories as CategoryDto[]
      ),
      render: (subCategory) =>
        subCategory?.name && <Tag color={'blue'}>{subCategory?.name}</Tag>,
      defaultFilteredValue: mapDefaultTableFiltersToColumnFilters(
        defaultFilters?.subCategory as number[]
      ),
    },
    {
      title: 'Color',
      dataIndex: 'color',
      key: 'color',
      filters: EntityCore.mapEntityDtoItemsToTableFilters(
        colors as EntityDto[]
      ),
      render: (color) => <span>{color.name}</span>,
      defaultFilteredValue: mapDefaultTableFiltersToColumnFilters(
        defaultFilters?.color as number[]
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, product) => (
        <div>
          <NavLink
            to={InventoriaRoutes.EDIT_PRODUCT.replace(
              ':id',
              product.id?.toString?.() ?? ''
            )}
          >
            <Button type={'link'} icon={<EditOutlined />} />
          </NavLink>
          <NavLink
            to={InventoriaRoutes.PLATFORM_PRODUCT.replace(
              ':productId',
              product.id?.toString?.() ?? ''
            )}
          >
            <Button type={'link'} icon={<LinkOutlined />} />
          </NavLink>
          <ManageProductStock productId={product.id as number} />
        </div>
      ),
    },
  ];

  return columns;
};
