import { UploadRequestOption } from 'rc-upload/lib/interface';
import { RcFile } from 'antd/lib/upload';
import { inventoriaApi } from '../../api/inventoria-api-factory/inventoriaApiFactory';
import axios from 'axios';

export class ExcelCore {
  static async uploadExcelSheet(params: UploadRequestOption) {
    try {
      const file = params.file as RcFile;
      const response = await inventoriaApi.s3Items.creatUploadExcelUrl({
        excelSheetName: file.name,
      });

      const { id, presignedUrl, httpMethod } = response.data;
      if (presignedUrl && id) {
        const uploadResponse = await axios({
          method: httpMethod,
          url: presignedUrl,
          data: file,
          headers: {
            'Content-Type': file.type,
          },
        });

        if (uploadResponse.status === 200) {
          params.onSuccess?.({});
        }
        return id;
      }
    } catch (error) {
      onerror?.('Upload failed!');
    }
  }
}
