import { useState } from 'react';
import { Popover, Button, Input, Space } from 'antd';
import { EntityDto } from '../../types';
import { AddPopupContainerProps } from './entityTable.types';
import { EditOutlined } from '@ant-design/icons';

interface Props extends AddPopupContainerProps {
  entityName: string;
  editApi: (entity: EntityDto) => void;
  record: EntityDto;
  loading: boolean;
}

const DefaultEntityEditPopover = (props: Props) => {
  const [name, setName] = useState(props?.record?.name);
  const [open, setOpen] = useState(false);

  const editEntity = () => {
    props.editApi({ name, id: props?.record?.id });
    setOpen(false);
  };

  return (
    <Popover
      open={open}
      destroyTooltipOnHide
      onOpenChange={(visible) => {
        setOpen(visible);
        if (!visible) {
          setName(props.record.name);
        }
      }}
      content={
        <Space size={'small'} direction={'vertical'}>
          <Input
            ref={(el) => {
              setTimeout(() => el?.focus(), 100);
            }}
            value={name}
            onPressEnter={editEntity}
            onChange={(e) => setName(e.target.value)}
            placeholder={`Enter ${props.entityName} Name`}
          />
          <Button
            disabled={name === ''}
            loading={props.loading}
            onClick={editEntity}
            type={'primary'}
          >
            Edit
          </Button>
        </Space>
      }
      title='Edit'
      trigger='click'
    >
      <Button type={'link'} icon={<EditOutlined />} />
    </Popover>
  );
};

export default DefaultEntityEditPopover;
