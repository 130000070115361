import { Button, Flex, Space, TableColumnsType, Typography } from 'antd';
import { NavLink } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useGetAllPlatformsQuery } from '../../store/entityRtk/apiSlice';
import { PlatformResponse } from '../../api/generated/data-contracts';
import { InventarioTable } from '../../components/table/inventarioTable';
import { LinkCell } from '../../components/table/cells/linkCell';

const columns: TableColumnsType<PlatformResponse> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 50,
    align: 'center',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'URL',
    dataIndex: 'url',
    key: 'url',
    render: (_, record) => {
      return <LinkCell link={record.url ?? ''} />;
    },
  },
];

export const PlatformsListingPage = () => {
  const { data, isLoading } = useGetAllPlatformsQuery();
  return (
    <Space direction='vertical' size={'small'} style={{ width: '100%' }}>
      <Flex align={'center'} justify={'space-between'}>
        <Typography.Title>Platforms</Typography.Title>
        <NavLink style={{ display: 'block' }} to={'/platforms/add'}>
          <Button type={'primary'} icon={<PlusOutlined />}>
            Add Platform
          </Button>
        </NavLink>
      </Flex>

      <InventarioTable
        dataSource={data}
        columns={columns}
        loading={isLoading}
        rowKey={(record) => record.id}
        pagination={false}
      />
    </Space>
  );
};
