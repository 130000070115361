import { InventarioTable } from '../table/inventarioTable';
import { useGetDecisionMakingRequestColumns } from './useGetDecisionMakingRequestColumns';
import { PlatformProductSubTable } from './platformProductSubTable';
import { useAppSelector } from '../../store/useAppDispatch';
import { decisionMakingRequestStateSelector } from '../../store/decision-making-request/decisionMakingRequest';

export const DecisionMakingRequestTable = () => {
  const columns = useGetDecisionMakingRequestColumns();
  const { data } = useAppSelector(decisionMakingRequestStateSelector);

  return (
    <div>
      <InventarioTable
        pagination={false}
        expandable={{
          expandedRowRender: (record, index) => (
            <PlatformProductSubTable
              productIndex={index}
              data={record.decisions}
            />
          ),
        }}
        rowKey='productId'
        dataSource={data}
        columns={columns}
      />
    </div>
  );
};
