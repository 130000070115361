import { useEffect, useState } from 'react';
import { ProductCore } from '../../../core/product/Product';
import { AddProductForm } from '../../../types';
import { notification, Skeleton, UploadFile } from 'antd';
import { AddProductPage } from '../add-product/addProductPage';
import { useNavigate, useParams } from 'react-router-dom';
import { InventoriaRoutes } from '../../../routes/routes';

export const EditProductPage = () => {
  const [productFormData, setProductFormData] = useState<AddProductForm>();
  const [images, setImages] = useState<UploadFile[] | null>(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const loadData = () => {
    setLoading(true);
    ProductCore.getProductById(parseInt(id as string))
      .then((response) => {
        const productResponse = response?.data?.content[0];
        if (!productResponse) {
          notification.error({ message: 'Product not found' });
          navigate(InventoriaRoutes.PRODUCTS);
          return;
        }

        const mappedProductForm =
          ProductCore.mapProductResponseToAddForm(productResponse);

        setProductFormData(mappedProductForm);

        const mappedImages = productResponse?.images?.map?.(
          ProductCore.mapImageProductResponseToUploadFile
        );

        setImages(mappedImages as UploadFile[]);
      })
      .catch(() => {
        navigate(InventoriaRoutes.PRODUCTS);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Skeleton active loading={loading}>
      <AddProductPage
        mode={'edit'}
        initialData={
          {
            ...productFormData,
            imagesUploadFiles: images,
            id: parseInt(id ?? ''),
          } as AddProductForm & {
            imagesUploadFiles: UploadFile[] | null;
          }
        }
      />
    </Skeleton>
  );
};
