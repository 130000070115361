import { Space, Tag, TagProps } from 'antd';

interface Props<T> {
  tags: T[];
  optionLabel: keyof T;
  color?: TagProps['color'];
}

export const TagsList = <T,>(props: Props<T>) => {
  return (
    <Space size={[0, 8]} wrap>
      {props.tags?.map?.((tag, index) => {
        const tagMapped = tag[props.optionLabel] as string;
        return (
          <Tag color={props.color} key={index}>
            {tagMapped}
          </Tag>
        );
      })}
    </Space>
  );
};
