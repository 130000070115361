import { combineReducers } from 'redux';

import { configureStore } from '@reduxjs/toolkit';
import { productListingPageSlice } from './product-listing-page/productListingPage';
import { api } from './entityRtk/apiSlice';
import { decisionMakingRequestSlice } from './decision-making-request/decisionMakingRequest';

// Combine the reducers
const rootReducer = combineReducers({
  [productListingPageSlice.name]: productListingPageSlice.reducer,
  [decisionMakingRequestSlice.name]: decisionMakingRequestSlice.reducer,
  [api.reducerPath]: api.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

export default store;
