import { Input, Select, Typography, UploadFile } from 'antd';
import { InventoriaForm } from '../../../components/form/form';
import { AddProductForm, EntityDto } from '../../../types';
import { ProductCore } from '../../../core/product/Product';
import { useState } from 'react';
import { ProductName } from '../../../components/product/product-name/productName';
import { defaultFilterFunction, mapToSelectOptionsType } from '../../../utils';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { ProductImageUpload } from '../../../components/product/image-upload/imageUpload';
import { RcFile } from 'antd/lib/upload';
import { useImagesIdByBackendId } from '../hooks/useImagesIdByBackendId';

import { useGetAllLookups } from '../../../hooks/useGetAllLookups';
import {
  useAddProductMutation,
  useEditProductMutation,
} from '../../../store/entityRtk/apiSlice';

const { TextArea } = Input;

interface Props {
  mode?: 'edit';
  initialData?: AddProductForm & {
    imagesUploadFiles: UploadFile[] | null;
    id?: number;
  };
}

const changingNameFields = [
  'model',
  'color',
  'productCategory',
  'productSubCategory',
];

export const AddProductPage = (props: Props) => {
  const [imageUploading, setImageUploading] = useState(false);
  const [form] = InventoriaForm.useForm();
  const [automaticProductName, setAutomaticProductName] = useState<string>('');
  const selectedCategory = InventoriaForm.useWatch('productCategory', form);
  const { categories, colors, models } = useGetAllLookups();
  const [addProduct, { isLoading }] = useAddProductMutation();
  const [editProudct, { isLoading: isEditingLoading }] =
    useEditProductMutation();
  const [uploadKey, setUploadKey] = useState(0);
  const { addImageIdByBackendId, deleteImageIdByBackendId } =
    useImagesIdByBackendId(props.initialData?.imagesUploadFiles ?? []);

  const subCategories: EntityDto[] =
    categories?.find((cat) => cat.id === selectedCategory?.value)
      ?.subCategories ?? [];

  const customRequestHandler = async (params: UploadRequestOption) => {
    try {
      setImageUploading(true);
      const castedFile = params.file as RcFile;
      const imageId = await ProductCore.uploadImage(params);
      if (imageId && castedFile.uid) {
        addImageIdByBackendId(castedFile.uid, imageId);
      }
      const images = Array.isArray(form.getFieldValue?.('productImages'))
        ? form.getFieldValue?.('productImages')
        : [];

      form.setFieldValue('productImages', [...images, imageId]);
    } catch (e) {
      /// handle error
    } finally {
      setImageUploading(false);
    }
  };

  const handleSaveProduct = async (values: AddProductForm) => {
    try {
      if (props.mode === 'edit') {
        /// Edit product flow
        const product = ProductCore.mapAddFormToProduct(values);
        await editProudct({ ...product, id: props.initialData?.id as number });
        setUploadKey(uploadKey + 1);
      } else {
        /// Add product flow
        const product = ProductCore.mapAddFormToProduct(values);
        await addProduct(product).unwrap();
      }
      form.resetFields();
      setAutomaticProductName('');
    } catch {
      //handle error
    }
  };

  return (
    <div>
      <Typography.Title level={2}>Add Product</Typography.Title>
      <InventoriaForm
        initialValues={props.initialData}
        form={form}
        onFieldsChange={(...params) => {
          const fieldName = params[0]?.[0].name?.[0];

          if (changingNameFields.includes(fieldName)) {
            setTimeout(() => {
              const newName = ProductCore.automaticProductNameGenerator(
                form.getFieldsValue()
              );

              setAutomaticProductName(newName);
            }, 0);
          }
        }}
        loading={imageUploading || isLoading || isEditingLoading}
        onFinish={handleSaveProduct}
      >
        <InventoriaForm.Item
          required
          name={'productName'}
          label={'Product Name'}
          rules={[
            { required: true, message: 'Please input product name!' },
            { max: 500, message: 'Product name cannot exceed 500 characters' },
          ]}
        >
          <ProductName
            automaticName={automaticProductName}
            onChange={(value) => {
              form.setFieldValue('productName', value);
            }}
          />
        </InventoriaForm.Item>

        <InventoriaForm.Item<AddProductForm>
          required
          name={'model'}
          label={'Model'}
          rules={[{ required: true, message: 'Please input product model!' }]}
        >
          <Select
            showSearch
            filterOption={defaultFilterFunction}
            options={mapToSelectOptionsType(models, 'name', 'id')}
            labelInValue
          />
        </InventoriaForm.Item>

        <InventoriaForm.Item<AddProductForm>
          required
          name={'color'}
          label={'Color'}
          rules={[{ required: true, message: 'Please input product color!' }]}
        >
          <Select
            showSearch
            filterOption={defaultFilterFunction}
            options={mapToSelectOptionsType(colors, 'name', 'id')}
            labelInValue
          />
        </InventoriaForm.Item>

        <InventoriaForm.Item<AddProductForm>
          name={'productCategory'}
          required
          label={'Product Category'}
          rules={[
            { required: true, message: 'Please input product category!' },
          ]}
        >
          <Select
            showSearch
            filterOption={defaultFilterFunction}
            options={mapToSelectOptionsType(categories, 'name', 'id')}
            labelInValue
            onChange={() => {
              form.setFieldValue('productSubCategory', null);
            }}
          />
        </InventoriaForm.Item>

        <InventoriaForm.Item<AddProductForm>
          name={'productSubCategory'}
          label={'Product Subcategory'}
        >
          <Select
            showSearch
            allowClear
            filterOption={defaultFilterFunction}
            disabled={!form.getFieldValue('productCategory')}
            options={mapToSelectOptionsType(subCategories, 'name', 'id')}
            labelInValue
          />
        </InventoriaForm.Item>

        <InventoriaForm.Item<AddProductForm>
          name={'similarModels'}
          label={'Similar Models'}
        >
          <Select
            filterOption={defaultFilterFunction}
            mode={'multiple'}
            options={mapToSelectOptionsType(models, 'name', 'id')}
            labelInValue
          />
        </InventoriaForm.Item>

        <InventoriaForm.Item<AddProductForm>
          name={'productDescription'}
          label={'Product Description'}
        >
          <TextArea dir={'auto'} />
        </InventoriaForm.Item>

        <InventoriaForm.Item name={'productImages'} hidden>
          <Input type={'hidden'} />
        </InventoriaForm.Item>

        <ProductImageUpload
          key={uploadKey}
          initialImageList={props.initialData?.imagesUploadFiles ?? []}
          onRemove={(file) => {
            const newImagesMap = deleteImageIdByBackendId(file.uid);
            const newUploadedImagesIds = Object.entries(newImagesMap).map(
              ([, value]) => value
            );
            form.setFieldValue('productImages', newUploadedImagesIds);
          }}
          customRequest={customRequestHandler}
        />
      </InventoriaForm>
    </div>
  );
};
