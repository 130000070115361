import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductListingPageFilters } from '../types';
import { RootState } from '../useAppDispatch';

interface InitialState {
  productListingPageFilters: ProductListingPageFilters;
  pagination: {
    total: number;
    current: number;
  };
}

const initialState: InitialState = {
  productListingPageFilters: {},
  pagination: {
    total: 0,
    current: 1,
  },
};

export const productListingPageSlice = createSlice({
  name: 'productListingPage',
  initialState: initialState,
  reducers: {
    changeFilters: (
      state,
      action: PayloadAction<Partial<ProductListingPageFilters>>
    ) => {
      state.productListingPageFilters = {
        ...state.productListingPageFilters,
        ...action.payload,
      };
    },
    addFilter: (
      state,
      action: PayloadAction<{
        key: keyof ProductListingPageFilters;
        value: any;
      }>
    ) => {
      const { key, value } = action.payload;
      state.productListingPageFilters[key] = value;
    },
    removeFilter: (
      state,
      action: PayloadAction<{
        key: keyof ProductListingPageFilters;
      }>
    ) => {
      const { key } = action.payload;
      state.productListingPageFilters[key] = null;
    },
    setPagination: (
      state,
      action: PayloadAction<{ total: number; current: number }>
    ) => {
      state.pagination = action.payload;
    },
  },
});

export const selectFilters = (state: RootState) =>
  state.productListingPage.productListingPageFilters;

export const selectPagination = (state: RootState) =>
  state.productListingPage.pagination;
