import { Button, Space, TableColumnsType, theme, Typography } from 'antd';
import { PlatformProductResponse } from '../../api/generated/data-contracts';
import { getStringFilterProp } from '../../components/table/table-search/stringSearchProps';
import { LinkCell } from '../../components/table/cells/linkCell';
import { NavLink } from 'react-router-dom';
import { InventoriaRoutes } from '../../routes/routes';
import { LinkOutlined } from '@ant-design/icons';
import { api } from '../../store/entityRtk/apiSlice';
import { InventarioTable } from '../../components/table/inventarioTable';
import React from 'react';
import { useSearchParamTablePagination } from '../../hooks/useSearchParamTablePagination';

export const PlatformProductsListingPage = () => {
  const { token } = theme.useToken();
  const columns: TableColumnsType<PlatformProductResponse> = [
    {
      title: 'Platform SKU',
      dataIndex: 'platformSku',
      key: 'platformSku',
      width: '20%',
      ...getStringFilterProp({
        dataIndex: 'platformSku',
      }),
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
      render: (_, record) => {
        return <LinkCell link={record.url ?? ''} />;
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      render: (name) =>
        name ?? <span style={{ color: token.colorTextTertiary }}>{'N/A'}</span>,
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '10%',
      render: (_, { productId }) => (
        <NavLink
          to={InventoriaRoutes.PLATFORM_PRODUCT.replace(
            ':productId',
            productId.toString()
          )}
        >
          <Button type={'link'} icon={<LinkOutlined />} />
        </NavLink>
      ),
    },
  ];

  const { pageNumber, pageSize, filter, handleTableChange } =
    useSearchParamTablePagination();
  const { data, isFetching } = api.useGetPlatformProductsQuery({
    pageNumber,
    pageSize,
    filter,
  });

  return (
    <Space direction='vertical' size={'small'} style={{ width: '100%' }}>
      <Typography.Title>Platform Products</Typography.Title>
      <InventarioTable
        dataSource={data?.content}
        columns={columns}
        loading={isFetching}
        rowKey={(record) => record.platformSku}
        pagination={{
          current: pageNumber + 1,
          pageSize: pageSize,
          total: data?.paginationInfo.totalNumberOfElements,
          showQuickJumper: true,
          showSizeChanger: true,
          hideOnSinglePage: true,
        }}
        onChange={handleTableChange}
        scroll={{ y: 500 }}
      />
    </Space>
  );
};
