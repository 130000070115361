import { useEffect, useState } from 'react';
import { Button, Popover } from 'antd';
import { AddPlatFormProductForm } from './platformProductForm';
import { PlusOutlined } from '@ant-design/icons';
import { PlatformProductCore } from '../../core/platform-product/PlatformProduct';
import { PlatformProductDto } from '../../types';
import { useGetPlatformProductColumns } from './useGetPlatformProductColumns';
import { InventarioTable } from '../table/inventarioTable';

interface Props {
  platformId: number;
  productId: number;
}

export const PlatformProductSkuTable = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<PlatformProductDto[]>([]);
  const [loading, setLoading] = useState(false);
  const columns = useGetPlatformProductColumns();

  const loadData = () => {
    setLoading(true);
    PlatformProductCore.getPlatformProductsByProductId(
      props.productId,
      props.platformId
    )
      .then((res) => {
        const platformProductsDtos = res.data.content.map(
          PlatformProductCore.mapPlatformProductToPlatformProductDto
        );
        setData(platformProductsDtos);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch(() => {});
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <div style={{ textAlign: 'right', marginBottom: 10 }}>
        <Popover
          placement='leftTop'
          trigger='click'
          onOpenChange={(visible) => setOpen(visible)}
          open={open}
          content={
            <AddPlatFormProductForm
              onAdd={loadData}
              platformId={props.platformId}
              productId={props.productId}
            />
          }
        >
          <Button
            onClick={() => {
              setOpen(true);
            }}
            type={'primary'}
            icon={<PlusOutlined />}
          >
            Add New
          </Button>
        </Popover>
      </div>
      <InventarioTable loading={loading} dataSource={data} columns={columns} />
    </div>
  );
};
