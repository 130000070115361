import { useState } from 'react';

export const usePagination = (page: number = 1) => {
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(page);

  return {
    total,
    currentPage,
    setCurrentPage,
    setTotal,
  };
};
