import { DecisionMakingRequestFormData } from './decisionMakingRequestFormData';
import { Card, Flex } from 'antd';
import { DecisionMakingRequestUploadFile } from './decisionMakingRequestUploadFile';

export const UploadDecisionMakingUploadSheetStep = () => {
  return (
    <Card>
      <Flex gap={10} style={{ flexDirection: 'column' }}>
        <DecisionMakingRequestFormData />
        <DecisionMakingRequestUploadFile />
      </Flex>
    </Card>
  );
};
