import { Typography } from 'antd';

const { Link } = Typography;

export const LinkCell = ({ link }: { link: string }) => {
  const linkRender = link.length > 50 ? `${link.substring(0, 50)}...` : link;
  if (link.startsWith('http') || link.startsWith('https')) {
    return (
      <Link target={'_blank'} href={link} copyable>
        {linkRender}
      </Link>
    );
  } else {
    const fixedLink = `//${link}`;
    return (
      <Link target={'_blank'} href={fixedLink} copyable>
        {linkRender}
      </Link>
    );
  }
};
