import { useEffect, useState } from 'react';
import { UploadFile } from 'antd';

export const useImagesIdByBackendId = (initialImages?: UploadFile[]) => {
  const [imagesIdByBackendId, setImagesIdByBackendId] = useState<
    Record<string, number>
  >({});

  const addImageIdByBackendId = (uid: string, id: number) => {
    const imagesMap = { ...imagesIdByBackendId };
    imagesMap[uid] = id;
    setImagesIdByBackendId(imagesMap);
  };

  const deleteImageIdByBackendId = (uid: string) => {
    delete imagesIdByBackendId[uid];
    setImagesIdByBackendId({ ...imagesIdByBackendId });
    return imagesIdByBackendId;
  };

  useEffect(() => {
    if (initialImages) {
      initialImages?.map?.((image) => {
        imagesIdByBackendId[image.uid] = parseInt(image.uid);
        setImagesIdByBackendId({ ...imagesIdByBackendId });
      });
    }
  }, [initialImages]);

  return {
    imagesIdByBackendId,
    addImageIdByBackendId,
    deleteImageIdByBackendId,
  };
};
