import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { MenuKeysEnum } from './enums';
import { ProductImageResponse } from './api/generated/data-contracts';

export interface AddProductForm {
  productName: string;
  productDescription?: string;
  model?: SelectLabelValue;
  color?: SelectLabelValue;
  productCategory: SelectLabelValue;
  productSubCategory?: SelectLabelValue;
  similarModels?: SelectLabelValue[];
  productImages?: number[];
}

export interface ProductDto {
  id?: number;
  internalProductCodeSku?: string;
  name?: string;
  productDescription?: string;
  model?: EntityDto;
  color?: EntityDto;
  category?: CategoryDto;
  subCategory?: EntityDto;
  productImageUrls?: ProductImageResponse[];
}

export interface CategoryDto {
  id: number;
  name: string;
  subCategories?: EntityDto[];
}

export interface EntityDto {
  id: number;
  name: string;
}

export interface RoutesMetadata {
  breadCrumb: ItemType[];
  activeKey: MenuKeysEnum;
}

export type LookupKeys = 'categories' | 'colors' | 'models';

export interface SelectLabelValue {
  label: string;
  value: number | string;
}

export interface ThunkState<T> {
  data: T;
  state: 'idle' | 'pending' | 'fulfilled' | 'rejected';
}

export interface ProductPlatformType {
  name: string;
  url: string;
  platformSku: string;
}

export interface PlatformProductDto {
  platformSku: string;
  url: string;
  name?: string;
  productId: number;
  platformId: number;
}

export interface DecisionMakingRequestGridRow {
  productId: number;
  productName: string;
  productSku: string;
  totalPlatformSales: number;
  totalShipmentQuantity: number;
  totalPurchaseQuantity: number;
  totalStocks: number;
  decisions: PlatformProductDecisionTableGridRow[];
}

export interface PlatformProductWarehouseDecisionTableGridRow {
  warehouseId: number;
  decidedShipmentQuantity: number;
  purchaseQuantity: number;
  warehouseStocks: number;
  warehouseName: string;
}

export interface PlatformProductDecisionTableGridRow {
  decisionProductId: number;
  willBePrepared: boolean;
  platformProductId: number;
  platformProductSku: string;
  platformProductUrl: string;
  platformSales: number;
  decidedWarehouseShipments: PlatformProductWarehouseDecisionTableGridRow[];
}

export interface DecisionMakingRequestDirtyRecord {
  willBePrepared?: boolean;
  decidedWarehouseShipments?: Record<number, number>;
}

export type DecisionMakingRequestDirtyRecordMap = Record<
  number,
  DecisionMakingRequestDirtyRecord
>;

export interface ProductStockDetailDto {
  quantity: number;
  warehouseId: number;
  warehouseName: string;
}

export enum DecisionMakingRequestStateTypeEnum {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
}

export interface DecisionMakingRequestListingPageGridRow {
  sheetName: string;
  state: DecisionMakingRequestStateTypeEnum;
  id: number;
}
