import { InventarioColumnType } from '../table/inventario.types';
import { DecisionMakingRequestGridRow } from '../../types';
import { getIdCell } from '../table/cells/idCell';

export const useGetDecisionMakingRequestColumns =
  (): InventarioColumnType<DecisionMakingRequestGridRow>[] => {
    return [
      { ...getIdCell(), dataIndex: 'productId' },
      { title: 'Product Name', dataIndex: 'productName' },
      { title: 'Product Sku', dataIndex: 'productSku' },
      { title: 'Total Platform Sales', dataIndex: 'totalPlatformSales' },
      {
        title: 'Total Shipment Quantity',
        dataIndex: 'totalShipmentQuantity',
      },
      {
        title: 'Total Purchase Quantity',
        dataIndex: 'totalPurchaseQuantity',
      },
      { title: 'Total Stocks', dataIndex: 'totalStocks' },
    ];
  };
