import {
  DecidedWarehouseShipmentUpdate,
  Decision,
  DecisionProductView,
  UpdateDecidedShipmentPayload,
  WarehouseStock,
} from '../../api/generated/data-contracts';
import {
  DecisionMakingRequestDirtyRecord,
  DecisionMakingRequestDirtyRecordMap,
  DecisionMakingRequestGridRow,
  DecisionMakingRequestStateTypeEnum,
  PlatformProductDecisionTableGridRow,
} from '../../types';
import { inventoriaApi } from '../../api/inventoria-api-factory/inventoriaApiFactory';

export class DecisionMakingRequest {
  static mapDecisionProductViewToDecisionMakingRequestGridRow(
    decisionProductView?: DecisionProductView[]
  ): DecisionMakingRequestGridRow[] {
    if (!decisionProductView) {
      return [];
    }

    return decisionProductView.map((decisionProductView) => {
      const mappedDecisions =
        DecisionMakingRequest.mapDecisionToPlatformProductDecisionTableGridRow(
          decisionProductView.decisions,
          decisionProductView.warehousesStocks
        );

      const totalPurchaseQuantity =
        DecisionMakingRequest.calculateTotalPurchaseQuantityForProduct(
          mappedDecisions
        );
      return {
        productId: decisionProductView.productId as number,
        productName: decisionProductView.productName as string,
        productSku: decisionProductView.productSku as string,
        totalStocks: decisionProductView.totalStocks as number,
        totalPurchaseQuantity,
        totalPlatformSales:
          DecisionMakingRequest.calculateTotalPlatformSalesForProduct(
            decisionProductView.decisions
          ),
        totalShipmentQuantity:
          DecisionMakingRequest.calculateTotalShipmentQuantityForProduct(
            decisionProductView.decisions
          ),
        decisions: mappedDecisions,
      };
    });
  }

  static calculateTotalPlatformSalesForProduct(decisions?: Decision[]): number {
    if (!decisions) {
      return 0;
    }
    return decisions.reduce((previousValue, currentValue) => {
      return previousValue + (currentValue?.platformSales ?? 0);
    }, 0);
  }

  static calculateTotalShipmentQuantityForProduct(
    decisions?: Decision[]
  ): number {
    if (!decisions) {
      return 0;
    }
    let count = 0;
    decisions?.forEach?.((decision) => {
      count +=
        decision?.decidedWarehouseShipments?.reduce((prev, curr) => {
          return prev + curr.decidedShipmentQuantity;
        }, 0) ?? 0;
    });

    return count;
  }

  static mapDecisionToPlatformProductDecisionTableGridRow(
    decisions?: Decision[],
    warehouseStock?: WarehouseStock[]
  ): PlatformProductDecisionTableGridRow[] {
    if (!decisions) {
      return [];
    }

    const warehouseMap = warehouseStock?.reduce(
      (previousValue, currentValue: WarehouseStock) => {
        if (currentValue.id) {
          previousValue[currentValue?.id as number] = currentValue;
        }
        return previousValue;
      },
      {} as Record<number, WarehouseStock>
    );

    return decisions.map((decision) => {
      return {
        decisionProductId: decision.decisionProductId as number,
        willBePrepared: (decision.willBePrepared as boolean) ?? false,
        platformProductId: decision.platformProductId as number,
        platformProductSku: decision.platformProductSku as string,
        platformProductUrl: decision.platformProductUrl as string,
        decidedWarehouseShipments:
          decision.decidedWarehouseShipments?.map((dws) => {
            const warehouse = warehouseMap?.[dws.warehouseId];
            return {
              ...dws,
              purchaseQuantity: DecisionMakingRequest.calculatePurchaseQuantity(
                warehouse?.quantity ?? 0,
                dws.decidedShipmentQuantity,
                decision.willBePrepared ?? true
              ),
              warehouseStocks: warehouse?.quantity ?? 0,
              warehouseName: warehouse?.name ?? '',
            };
          }) ?? [],
        platformSales: decision.platformSales as number,
      };
    });
  }

  static calculatePurchaseQuantity(
    stocks: number,
    decidedShipment: number,
    willBePrepared: boolean
  ) {
    if (stocks === 0 || !willBePrepared) {
      return decidedShipment;
    }

    if (stocks > decidedShipment) {
      return 0;
    }

    return decidedShipment - stocks;
  }

  static calculateTotalPurchaseQuantityForProduct(
    decisions: PlatformProductDecisionTableGridRow[]
  ): number {
    let totalPurchaseQuantity = 0;

    // Loop over all decisions in the array
    for (const decision of decisions) {
      // Loop over all warehouse decisions associated with each product
      for (const warehouseDecision of decision.decidedWarehouseShipments) {
        // Sum up all purchase quantities
        totalPurchaseQuantity += warehouseDecision.purchaseQuantity;
      }
    }

    return totalPurchaseQuantity;
  }

  static calculateTotalPurchaseQuantityForWarehouseProducts(
    platformProductDecisionTableGridRow: PlatformProductDecisionTableGridRow
  ) {
    const { willBePrepared } = platformProductDecisionTableGridRow;
    for (const warehouseProduct of platformProductDecisionTableGridRow.decidedWarehouseShipments) {
      if (!willBePrepared) {
        warehouseProduct.purchaseQuantity =
          warehouseProduct.decidedShipmentQuantity;
        continue;
      }

      warehouseProduct.purchaseQuantity =
        DecisionMakingRequest.calculatePurchaseQuantity(
          warehouseProduct.warehouseStocks,
          warehouseProduct.decidedShipmentQuantity,
          willBePrepared
        );
    }
  }

  private static mapDecisionMakingRequestDirtyRecordToDecidedWarehouseShipmentUpdate(
    decisionMakingRequestDirtyRecord?: DecisionMakingRequestDirtyRecord
  ): DecidedWarehouseShipmentUpdate[] {
    if (decisionMakingRequestDirtyRecord?.decidedWarehouseShipments) {
      return Object.entries(
        decisionMakingRequestDirtyRecord.decidedWarehouseShipments
      ).map(([key, value]) => {
        return {
          warehouseId: parseInt(key),
          decidedShipmentQuantity: value,
        };
      });
    }

    return [];
  }

  static mapDecisionMakingRequestDirtyRecordMapToUpdateDecidedShipmentPayload(
    decisionMakingDirtyMap: DecisionMakingRequestDirtyRecordMap
  ): UpdateDecidedShipmentPayload {
    return Object.entries(decisionMakingDirtyMap).map(([key, value]) => {
      return {
        willBePrepared: value.willBePrepared,
        decisionProductId: parseInt(key),
        decidedWarehouseShipments:
          DecisionMakingRequest.mapDecisionMakingRequestDirtyRecordToDecidedWarehouseShipmentUpdate(
            value
          ),
      };
    });
  }

  static async getDecisionMakingRequestSheet(id: number): Promise<{
    mappedData: DecisionMakingRequestGridRow[];
    sheetState: DecisionMakingRequestStateTypeEnum;
  }> {
    const sheetContentRes =
      await inventoriaApi.decisionRequest.findDecisionProducts(id);

    return {
      mappedData:
        DecisionMakingRequest.mapDecisionProductViewToDecisionMakingRequestGridRow(
          sheetContentRes.data.decisionProducts
        ),
      sheetState:
        (sheetContentRes.data.state as DecisionMakingRequestStateTypeEnum) ??
        DecisionMakingRequestStateTypeEnum.PENDING,
    };
  }
}
