import { Button, Form, Input, InputRef } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { ProductPlatformType } from '../../types';
import { PlatformProductCore } from '../../core/platform-product/PlatformProduct';

interface Props {
  productId: number;
  platformId: number;
  onAdd: () => void;
}

export const AddPlatFormProductForm = (props: Props) => {
  const [form] = Form.useForm();
  const inputRef = useRef<InputRef>(null);
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: ProductPlatformType) => {
    try {
      setLoading(true);
      await PlatformProductCore.savePlatformProduct(
        values,
        props.productId,
        props.platformId
      );
      props.onAdd();
    } catch (e) {
      /// catch errors
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <Form
      form={form}
      layout='vertical' // Adjust layout as needed (e.g., "horizontal")
      onFinish={onFinish}
    >
      <Form.Item name='name'>
        <Input ref={inputRef} placeholder={'Product Name'} name='name' />
      </Form.Item>
      <Form.Item
        required
        name='platformSku'
        rules={[{ required: true, message: 'Please enter Platform SKU' }]}
      >
        <Input placeholder={'Platform SKU'} name='platformSku' />
      </Form.Item>
      <Form.Item
        name='url'
        required
        rules={[
          { required: true, message: 'Please enter URL' },
          { type: 'url', message: 'Please enter a valid URL' },
        ]}
      >
        <Input placeholder={'URL'} name='url' />
      </Form.Item>
      <Form.Item>
        <Button
          loading={loading}
          disabled={loading}
          type='primary'
          htmlType='submit'
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};
