import { InventarioTable } from '../table/inventarioTable';
import { InventarioColumnType } from '../table/inventario.types';
import { PlatformProductWarehouseDecisionTableGridRow } from '../../types';
import { DecidedShipmentQuantityCellRenderer } from './decidedShipmentQuantityCellRenderer';

interface Props {
  data: PlatformProductWarehouseDecisionTableGridRow[];
  productIndex: number;
  decisionIndex: number;
}

export const DecisionMakingRequestWarehouseTable = (props: Props) => {
  const columns: InventarioColumnType<PlatformProductWarehouseDecisionTableGridRow>[] =
    [
      {
        title: 'Warehouse Name',
        dataIndex: 'warehouseName',
      },
      {
        title: 'Decided Shipment',
        dataIndex: 'decidedShipmentQuantity',
        render: (value, record, index) => {
          return (
            <DecidedShipmentQuantityCellRenderer
              productIndex={props.productIndex}
              decidedWarehouseShipmentIndex={index}
              decisionIndex={props.decisionIndex}
              value={value}
            />
          );
        },
      },
      {
        title: 'Warehouse Stocks',
        dataIndex: 'warehouseStocks',
      },
      {
        title: 'Purchase Quantity',
        dataIndex: 'purchaseQuantity',
      },
    ];
  return (
    <InventarioTable
      pagination={false}
      dataSource={props.data}
      columns={columns}
      rowKey='warehouseId'
    />
  );
};
