import { useState } from 'react';
import { usePagination } from './usePagination';

export const useGetServerTablePagination = <T>() => {
  const [data, setData] = useState<T[]>([]);
  const { total, currentPage, setCurrentPage, setTotal } = usePagination(1);

  return {
    total,
    data,
    currentPage,
    setCurrentPage,
    setData,
    setTotal,
  };
};
