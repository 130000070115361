import { HttpClient } from '../generated/http-client';
import { inventoriaInstance } from '../axios/instance';
import { Categories } from '../generated/Categories';
import { Products } from '../generated/Products';
import { Colors } from '../generated/Colors';

import { S3Items } from '../generated/S3Items';
import { Models } from '../generated/Models';
import { Excel } from '../generated/Excel';
import { Tasks } from '../generated/Tasks';
import { PlatformsProducts } from '../generated/PlatformsProducts';
import { Warehouses } from '../generated/Warehouses';
import { Platforms } from '../generated/Platforms';
import { Shipments } from '../generated/Shipments';
import { DecisionRequest } from '../generated/DecisionRequest';
import { ProductStocks } from '../generated/ProductStocks';

export const inventoriaApiFactory = <T extends HttpClient>(
  ApiType: new () => T
) => {
  const api = new ApiType();
  api.instance = inventoriaInstance;
  return api;
};

export const inventoriaApi = {
  categories: inventoriaApiFactory(Categories),
  products: inventoriaApiFactory(Products),
  colors: inventoriaApiFactory(Colors),
  models: inventoriaApiFactory(Models),
  s3Items: inventoriaApiFactory(S3Items),
  excel: inventoriaApiFactory(Excel),
  tasks: inventoriaApiFactory(Tasks),
  platformProducts: inventoriaApiFactory(PlatformsProducts),
  warehouses: inventoriaApiFactory(Warehouses),
  platforms: inventoriaApiFactory(Platforms),
  shipments: inventoriaApiFactory(Shipments),
  decisionRequest: inventoriaApiFactory(DecisionRequest),
  productStocks: inventoriaApiFactory(ProductStocks),
};
