import { EntityTable } from '../../../components/entity-table/entityTable';
import {
  useAddColorMutation,
  useEditColorMutation,
} from '../../../store/entityRtk/apiSlice';
import { useGetAllLookups } from '../../../hooks/useGetAllLookups';

export const ColorsListingPage = () => {
  const [addColor, { isLoading }] = useAddColorMutation();
  const [editColor, { isLoading: isEditLoading }] = useEditColorMutation();
  const { colors } = useGetAllLookups();

  return (
    <EntityTable
      dataSource={colors}
      loading={isLoading || isEditLoading}
      entityName={'Color'}
      editApi={(color) => {
        editColor(color);
      }}
      addApi={(newItemName) => {
        addColor(newItemName);
      }}
    />
  );
};
