import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import App from '../App';
import { InventoriaRoutes } from './routes';
import { AddProductPage } from '../pages/products/add-product/addProductPage';
import { useLoadLookups } from '../hooks/useLoadLookups';
import { ProductsListingPage } from '../pages/products/products-listing/productsListingPage';
import { RoutesMetadata } from '../types';
import { MenuKeysEnum } from '../enums';
import { CategoriesListingPage } from '../pages/dictionaries/categories/categoriesListingPage';
import { ModelsListingPage } from '../pages/dictionaries/models/modelsListingPage';
import { ColorsListingPage } from '../pages/dictionaries/colors/colorsListingPage';
import { UploadExcelPage } from '../pages/upload-excel/uploadExcelPage';
import { EditProductPage } from '../pages/products/edit-product/editProductPage';
import { WarehousesListingPage } from '../pages/warehouses/warehousesListingPage';
import { PlatformProductsListingPage } from '../pages/platform-products/platformProductsListingPage';
import { PlatformsListingPage } from '../pages/platforms/platformsListingPage';
import { WarehousesAddPage } from '../pages/warehouses/warehousesAddPage';
import { PlatformsAddPage } from '../pages/platforms/platformsAddPage';
import { PlatformProductPage } from '../pages/platform-product/platformProductPage';
import { NotFoundPage } from '../pages/not-found/notFoundPage';
import { DecisionMakingRequestListingPage } from '../pages/decision-making-request/listing-page/decisionMakingRequestListingPage';
import { DecisionMakingRequestCreationPage } from '../pages/decision-making-request/creation-page/decisionMakingRequestCreationPage';
import { ShipmentsListingPage } from '../pages/shipments/shipmentsListingPage';
import { ShipmentDetailsPage } from '../pages/shipments/shipmentDetailsPage';
import { DecisionMakingRequestDetailsPage } from '../pages/decision-making-request/details-page/decisionMakingRequestDetailsPage';

const router = createBrowserRouter([
  {
    path: '*',
    element: <NotFoundPage />,
  },
  {
    path: InventoriaRoutes.HOME,
    element: <App />,
    children: [
      {
        path: InventoriaRoutes.PRODUCTS,
        element: <ProductsListingPage />,
      },
      {
        element: <Navigate to={InventoriaRoutes.PRODUCTS} />,
        index: true,
      },
      {
        path: InventoriaRoutes.ADD_PRODUCT,
        element: <AddProductPage />,
      },
      {
        path: InventoriaRoutes.EDIT_PRODUCT,
        element: <EditProductPage />,
      },
      {
        path: InventoriaRoutes.COLORS,
        element: <ColorsListingPage />,
      },
      {
        path: InventoriaRoutes.MODELS,
        element: <ModelsListingPage />,
      },
      {
        path: InventoriaRoutes.CATEGORIES,
        element: <CategoriesListingPage />,
      },
      {
        path: InventoriaRoutes.EXCEL,
        element: <UploadExcelPage />,
      },
      {
        path: InventoriaRoutes.WAREHOUSES,
        element: <WarehousesListingPage />,
      },
      {
        path: InventoriaRoutes.WAREHOUSES_ADD,
        element: <WarehousesAddPage />,
      },
      {
        path: InventoriaRoutes.PLATFORM_PRODUCTS,
        element: <PlatformProductsListingPage />,
      },
      {
        path: InventoriaRoutes.PLATFORMS,
        element: <PlatformsListingPage />,
      },
      {
        path: InventoriaRoutes.SHIPMENT,
        element: <ShipmentDetailsPage />,
      },
      {
        path: InventoriaRoutes.SHIPMENTS,
        element: <ShipmentsListingPage />,
      },
      {
        path: InventoriaRoutes.PLATFORMS_ADD,
        element: <PlatformsAddPage />,
      },
      {
        path: InventoriaRoutes.PLATFORM_PRODUCT,
        element: <PlatformProductPage />,
      },
      {
        path: InventoriaRoutes.DECISION_MAKING_REQUEST,
        element: <DecisionMakingRequestListingPage />,
      },
      {
        path: InventoriaRoutes.ADD_DECISION_MAKING_REQUEST,
        element: <DecisionMakingRequestCreationPage />,
      },
      {
        path: InventoriaRoutes.DECISION_MAKING_REQUEST_DETAILS,
        element: <DecisionMakingRequestDetailsPage />,
      },
    ],
  },
]);

export const routesMetadata: Record<string, RoutesMetadata> = {
  [InventoriaRoutes.ADD_PRODUCT]: {
    activeKey: MenuKeysEnum.PRODUCTS,
    breadCrumb: [
      { title: 'Products', href: InventoriaRoutes.PRODUCTS },
      { title: 'Add Product' },
    ],
  },
  [InventoriaRoutes.PRODUCTS]: {
    activeKey: MenuKeysEnum.PRODUCTS,
    breadCrumb: [{ title: 'Products' }],
  },
  [InventoriaRoutes.EDIT_PRODUCT]: {
    activeKey: MenuKeysEnum.PRODUCTS,
    breadCrumb: [
      { title: 'Products', href: InventoriaRoutes.PRODUCTS },
      { title: 'Add Product' },
    ],
  },
  [InventoriaRoutes.COLORS]: {
    activeKey: MenuKeysEnum.COLORS,
    breadCrumb: [{ title: 'Colors' }],
  },
  [InventoriaRoutes.MODELS]: {
    activeKey: MenuKeysEnum.MODELS,
    breadCrumb: [{ title: 'Colors' }],
  },
  [InventoriaRoutes.CATEGORIES]: {
    activeKey: MenuKeysEnum.CATEGORIES,
    breadCrumb: [{ title: 'Categories' }],
  },
  [InventoriaRoutes.TAGS]: {
    activeKey: MenuKeysEnum.TAGS,
    breadCrumb: [{ title: 'Tags' }],
  },
  [InventoriaRoutes.EXCEL]: {
    activeKey: MenuKeysEnum.UPLOAD_EXCEL,
    breadCrumb: [{ title: 'Upload Excel' }],
  },
  [InventoriaRoutes.WAREHOUSES]: {
    activeKey: MenuKeysEnum.WAREHOUSES,
    breadCrumb: [{ title: 'Warehouses' }],
  },
  [InventoriaRoutes.WAREHOUSES_ADD]: {
    activeKey: MenuKeysEnum.WAREHOUSES,
    breadCrumb: [
      { title: 'Warehouses', href: InventoriaRoutes.WAREHOUSES },
      { title: 'Add Warehouse' },
    ],
  },
  [InventoriaRoutes.PLATFORM_PRODUCTS]: {
    activeKey: MenuKeysEnum.PLATFORM_PRODUCTS,
    breadCrumb: [{ title: 'Platform Products' }],
  },
  [InventoriaRoutes.PLATFORMS]: {
    activeKey: MenuKeysEnum.PLATFORMS,
    breadCrumb: [{ title: 'Platforms' }],
  },
  [InventoriaRoutes.SHIPMENTS]: {
    activeKey: MenuKeysEnum.SHIPMENTS,
    breadCrumb: [{ title: 'Shipments' }],
  },
  [InventoriaRoutes.PLATFORMS_ADD]: {
    activeKey: MenuKeysEnum.PLATFORMS,
    breadCrumb: [
      { title: 'Platforms', href: InventoriaRoutes.PLATFORMS },
      { title: 'Add Platform' },
    ],
  },
  [InventoriaRoutes.DECISION_MAKING_REQUEST]: {
    activeKey: MenuKeysEnum.DECISION_MAKING_REQUEST,
    breadCrumb: [{ title: 'Decision Making Request' }],
  },
  [InventoriaRoutes.ADD_DECISION_MAKING_REQUEST]: {
    activeKey: MenuKeysEnum.DECISION_MAKING_REQUEST,
    breadCrumb: [
      {
        title: 'Decision Making Request',
        href: InventoriaRoutes.DECISION_MAKING_REQUEST,
      },
      { title: 'Add Decision Making Request' },
    ],
  },
  [InventoriaRoutes.DECISION_MAKING_REQUEST_DETAILS]: {
    activeKey: MenuKeysEnum.DECISION_MAKING_REQUEST,
    breadCrumb: [
      {
        title: 'Decision Making Request',
        href: InventoriaRoutes.DECISION_MAKING_REQUEST,
      },
      { title: 'Decision Making Request Detail' },
    ],
  },
};

export const InventoriaRouter = () => {
  useLoadLookups();
  return <RouterProvider router={router} />;
};
