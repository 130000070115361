import { useState } from 'react';

export const useGetServerSideState = () => {
  const [rsqlFilters, setRsqlFilters] = useState<string>('');

  return {
    rsqlFilters,
    setRsqlFilters,
  };
};
