/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  DecisionRequestPage,
  DecisionRequestView,
  QueryOptions,
  UpdateDecidedShipmentPayload,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class DecisionRequest<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name FindDecisionProducts
   * @summary View for decision products
   * @request GET:/decision-request/{decisionRequestId}/decision-products
   */
  findDecisionProducts = (
    decisionRequestId: number,
    params: RequestParams = {}
  ) =>
    this.request<DecisionRequestView, any>({
      path: `/decision-request/${decisionRequestId}/decision-products`,
      method: 'GET',
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name FindAllDecisionRequests
   * @summary find all decision requests
   * @request GET:/decision-request
   */
  findAllDecisionRequests = (
    query?: {
      /** Paging, sorting, and filtration parameters */
      queryOptions?: QueryOptions;
    },
    params: RequestParams = {}
  ) =>
    this.request<DecisionRequestPage, any>({
      path: `/decision-request`,
      method: 'GET',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name UpdateDecidedShipment
   * @summary update decision products
   * @request PUT:/decision-request/{id}/decided-shipment
   */
  updateDecidedShipment = (
    id: number,
    data: UpdateDecidedShipmentPayload,
    params: RequestParams = {}
  ) =>
    this.request<string, any>({
      path: `/decision-request/${id}/decided-shipment`,
      method: 'PUT',
      body: data,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name ConfirmDecision
   * @summary confirm decision to create shipment with deducting stocks and creat purchasing items
   * @request POST:/decision-request/{id}/confirm
   */
  confirmDecision = (id: number, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/decision-request/${id}/confirm`,
      method: 'POST',
      format: 'json',
      ...params,
    });
}
