import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import { InventoriaRouter } from './routes/router';
import { Provider } from 'react-redux';
import store from './store';
import { keycloak } from './keycloak/keycloak';

const root = ReactDOM.createRoot(document.getElementById('root')!);

keycloak
  .init({
    onLoad: 'login-required',
  })
  .then((authenticated) => {
    if (authenticated) {
      root.render(
        <Provider store={store}>
          <InventoriaRouter />
        </Provider>
      );
    } else {
      keycloak.login();
    }
  })
  .catch(() => {});
