import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, TableColumnType } from 'antd';

interface Props {
  dataIndex: string;
  localFilter?: boolean;
  filterFunction?: (text: string, record: any) => boolean;
}

export const getStringFilterProp = <DataType,>({
  dataIndex,
  localFilter,
  filterFunction,
}: Props): TableColumnType<DataType> => {
  return {
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => {
      return (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            ref={(ref) => setTimeout(() => ref?.focus(), 0)}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type='primary'
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size='small'
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters?.();
                setSelectedKeys(['']);
                confirm();
              }}
              size='small'
              style={{ width: 90 }}
            >
              Reset
            </Button>
            <Button
              type='link'
              size='small'
              onClick={() => {
                confirm({ closeDropdown: false });
              }}
            >
              Filter
            </Button>
            <Button
              type='link'
              size='small'
              onClick={() => {
                close();
              }}
            >
              Close
            </Button>
          </Space>
        </div>
      );
    },
    onFilter: localFilter
      ? (value, record) => {
          const recordValue = (record as any)?.[dataIndex];
          if (filterFunction) {
            return filterFunction(value as string, record);
          }
          return typeof recordValue === 'string'
            ? recordValue
                ?.toString()
                ?.toLowerCase()
                ?.includes(value?.toString?.()?.toLowerCase?.() as string)
            : false;
        }
      : undefined,
  };
};
