import { useSearchParams } from 'react-router-dom';
import { TableProps } from 'antd';
import { mapAntdTableFiltersToRSQL } from '../utils';

export function useSearchParamTablePagination() {
  const [searchParams, setSearchParams] = useSearchParams();

  const pageNumber = parseInt(searchParams.get('pageNumber') ?? '0');
  const pageSize = parseInt(searchParams.get('pageSize') ?? '10');
  const filter = searchParams.get('filter') ?? '';

  const handleTableChange: TableProps['onChange'] = (pagination, filters) => {
    const rsqlFilter = mapAntdTableFiltersToRSQL(filters);
    setSearchParams({
      pageNumber: `${pagination.current! - 1}`,
      pageSize: `${pagination.pageSize}`,
      filter: rsqlFilter,
    });
  };
  return { pageNumber, pageSize, filter, handleTableChange };
}
