import { Flex, notification, Tabs, Typography } from 'antd';
import { PlatformProductSkuTable } from '../../components/platform-product/platformProductSkuTable';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ProductCore } from '../../core/product/Product';
import { Product } from '../../api/generated/data-contracts';
import { ProductDto } from '../../types';
import { InventoriaRoutes } from '../../routes/routes';
import { useGetAllPlatformsQuery } from '../../store/entityRtk/apiSlice';

export const PlatformProductPage = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState<ProductDto>();
  const navigate = useNavigate();
  const { data: platforms } = useGetAllPlatformsQuery();

  useEffect(() => {
    ProductCore.getProductById(parseInt(productId as string)).then(
      (response) => {
        const product = response?.data?.content[0] as Product;
        if (!product) {
          notification.error({ message: 'Product not found' });
          navigate(InventoriaRoutes.PRODUCTS);
          return;
        }
        const productDto = ProductCore.mapProductResponseToProductDto(product);
        setProduct(productDto);
      }
    );
  }, []);

  return (
    <div>
      <Flex align={'center'} justify={'space-between'}>
        <Typography.Title level={2}>{product?.name}</Typography.Title>
      </Flex>
      <Tabs type='card' defaultActiveKey={'1'}>
        {platforms?.map?.((platform) => (
          <Tabs.TabPane tab={platform.name} key={platform.id}>
            <PlatformProductSkuTable
              platformId={platform.id}
              productId={parseInt(productId as string)}
            />
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};
