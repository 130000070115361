import { GetProp, Modal, Upload, UploadFile, UploadProps } from 'antd';
import { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { UploadRequestOption } from 'rc-upload/lib/interface';

interface Props {
  customRequest: (options: UploadRequestOption) => void;
  onRemove?: (file: UploadFile) => void;
  initialImageList?: UploadFile[];
}

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

export const ProductImageUpload = (props: Props) => {
  const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1)
    );
  };

  const handleCancel = () => setPreviewOpen(false);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  return (
    <div>
      <Upload
        defaultFileList={props.initialImageList}
        onRemove={props.onRemove}
        onPreview={handlePreview}
        listType='picture-card'
        customRequest={props.customRequest}
      >
        <button style={{ border: 0, background: 'none' }} type='button'>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Upload</div>
        </button>
      </Upload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt='example' style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </div>
  );
};
