import { Button, message, Typography, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { ExcelCore } from '../../core/excel/Excel';
import { inventoriaApi } from '../../api/inventoria-api-factory/inventoriaApiFactory';
import { useAppDispatch, useAppSelector } from '../../store/useAppDispatch';
import { decisionMakingRequestStateSelector } from '../../store/decision-making-request/decisionMakingRequest';
import { SelectLabelValue } from '../../types';
import { pollUntilDone } from '../../utils';
import { decisionMakingRequestActions } from '../../store/decision-making-request/decisionMakingRequestActions';
import { DecisionMakingRequest } from '../../core/decision-making-request/DecisionMakingRequest';

export const DecisionMakingRequestUploadFile = () => {
  const { currentPlatform } = useAppSelector(
    decisionMakingRequestStateSelector
  );

  const dispatch = useAppDispatch();

  return (
    <div style={{ width: '400px' }}>
      <Upload
        customRequest={async (params) => {
          params.onProgress?.({ percent: 1 });
          const uploadedId = await ExcelCore.uploadExcelSheet(params);
          if (uploadedId && currentPlatform !== null) {
            params.onProgress?.({ percent: 50 });
            const res = await inventoriaApi.excel.startPublishingDecisionExcel(
              uploadedId,
              (currentPlatform as SelectLabelValue)?.value as number
            );
            params.onProgress?.({ percent: 75 });

            const pollRes = await pollUntilDone(res?.data?.id as number, 1000);

            const pollStatus = pollRes?.data?.status;
            const outputId = pollRes?.data?.outputId;

            if (pollStatus === 'FAILED' || !outputId) {
              message.error(pollRes?.data?.message);
              //@ts-expect-error no correct event
              params.onError();
              return;
            }

            const { mappedData, sheetState } =
              await DecisionMakingRequest.getDecisionMakingRequestSheet(
                parseInt(outputId)
              );

            dispatch(
              decisionMakingRequestActions.changeState({
                currentStep: 1,
                data: mappedData,
                sheetId: parseInt(outputId),
                sheetState,
              })
            );
          }
        }}
        maxCount={1}
      >
        <Typography.Text>Select Sheet</Typography.Text>
        <Button
          style={{ display: 'block', width: '100%' }}
          icon={<UploadOutlined />}
        >
          Click to Upload
        </Button>
      </Upload>
    </div>
  );
};
