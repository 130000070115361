import { AddProductForm, ProductDto } from '../../types';
import {
  CategoryResponse,
  ColorResponse,
  ModelResponse,
  Product,
  ProductImageResponse,
  ProductResponse,
  SubCategoryResponse,
} from '../../api/generated/data-contracts';
import { inventoriaApi } from '../../api/inventoria-api-factory/inventoriaApiFactory';
import { getLabelFromSelect, mapToSelectOptionsType } from '../../utils';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { RcFile } from 'antd/lib/upload';
import axios from 'axios';
import { EntityCore } from '../entity/Entity';
import { UploadFile } from 'antd';

export class ProductCore {
  static saveProduct(data: AddProductForm) {
    const mappedProduct = this.mapAddFormToProduct(data);
    return inventoriaApi.products.saveProduct(mappedProduct);
  }

  static editProduct(data: AddProductForm, id: number) {
    const mappedProduct = this.mapAddFormToProduct(data);
    return inventoriaApi.products.updateProduct(id, mappedProduct);
  }

  static getProductById(productId: number) {
    return inventoriaApi.products.findAllProducts({
      queryOptions: { filter: `id == ${productId}` },
    });
  }

  static mapAddFormToProduct(addProductForm: AddProductForm): Product {
    return {
      name: addProductForm.productName,
      modelId: addProductForm.model?.value as number,
      productDescription: addProductForm.productDescription,
      colorId: addProductForm.color?.value as number,
      categoryId: addProductForm.productCategory?.value as number,
      subCategoryId:
        (addProductForm.productSubCategory?.value as number) ?? null,
      similarModelsIds: addProductForm?.similarModels?.map?.(
        (model) => model.value as number
      ),
      s3ImagesIds: addProductForm?.productImages?.map(
        (imageId) => imageId as number
      ),
    };
  }

  static mapProductResponseToAddForm(
    productResponse: ProductResponse
  ): AddProductForm {
    return {
      productName: productResponse.name as string,
      model: mapToSelectOptionsType(
        [productResponse.model] as ModelResponse[],
        'name',
        'id'
      )[0],
      productDescription: productResponse.productDescription,
      color: mapToSelectOptionsType(
        [productResponse.color] as ColorResponse[],
        'name',
        'id'
      )[0],
      productCategory: mapToSelectOptionsType(
        [productResponse.category] as CategoryResponse[],
        'name',
        'id'
      )[0],
      productSubCategory: productResponse.subCategory
        ? mapToSelectOptionsType(
            [productResponse.subCategory] as SubCategoryResponse[],
            'name',
            'id'
          )[0]
        : undefined,
      similarModels: mapToSelectOptionsType(
        productResponse.similarModels as ModelResponse[],
        'name',
        'id'
      ),
      productImages: productResponse?.images?.map(
        (image) => image.id
      ) as number[],
    };
  }

  static mapProductResponseToProductDto(
    productResponse: ProductResponse
  ): ProductDto {
    return {
      id: productResponse.id,
      internalProductCodeSku: productResponse.internalProductCodeSku,
      name: productResponse.name,
      productDescription: productResponse.productDescription,
      model: EntityCore.mapEntityResponseToEntityDto(productResponse.model),
      color: EntityCore.mapEntityResponseToEntityDto(productResponse.color),
      category: {
        name: productResponse.category?.name as string,
        id: productResponse.category?.id as number,
      },
      productImageUrls: productResponse.images,
      subCategory: EntityCore.mapEntityResponseToEntityDto(
        productResponse.subCategory
      ),
    };
  }

  static automaticProductNameGenerator(formValues?: AddProductForm) {
    const category = getLabelFromSelect(formValues?.productCategory);
    const model = getLabelFromSelect(formValues?.model);
    const color = getLabelFromSelect(formValues?.color);
    const arr = [category, model, color].filter(Boolean);
    return arr.join('-');
  }

  static async uploadImage({ file, filename, onSuccess }: UploadRequestOption) {
    try {
      const response = await inventoriaApi.s3Items.creatUploadImageUrl({
        itemName: filename ?? '',
      });

      const filesCasted = file as RcFile;

      const { presignedUrl, httpMethod, id } = response.data;

      if (presignedUrl) {
        const uploadResponse = await axios({
          method: httpMethod,
          url: presignedUrl,
          data: file,
          headers: {
            'Content-Type': filesCasted.type,
          },
        });

        if (uploadResponse.status === 200) {
          onSuccess?.({});
        }

        return id;
      }
    } catch (error) {
      onerror?.('Upload failed!');
    }
  }

  static mapImageProductResponseToUploadFile(
    image: ProductImageResponse
  ): UploadFile | null {
    if (!image) {
      return null;
    }
    return {
      uid: `${image.id}`,
      name: image.imageUrl as string,
      status: 'done',
      url: image.imageUrl,
    };
  }
}
