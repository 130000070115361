import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CategoryDto, EntityDto, ProductDto } from '../../types';
import { inventoriaApi } from '../../api/inventoria-api-factory/inventoriaApiFactory';
import { EntityCore } from '../../core/entity/Entity';
import {
  DecisionRequestResponse,
  Platform,
  PlatformProductPageResponse,
  PlatformResponse,
  Product,
  ProductResponse,
  QueryOptions,
  ShipmentDetailsResponse,
  ShipmentSummaryPageResponse,
  Warehouse,
  WarehouseResponse,
} from '../../api/generated/data-contracts';
import { CategoryCore } from '../../core/category/Category';
import { notification } from 'antd';
import { ProductCore } from '../../core/product/Product';
import { Config } from '../../config';

export const api = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: 'https://pokeapi.co/api/v2/' }),
  reducerPath: 'entitiesApi',
  tagTypes: [
    'Models',
    'Colors',
    'Categories',
    'Warehouses',
    'Platforms',
    'Products',
    'Shipments',
  ],
  endpoints: (builder) => ({
    // COLORS
    getAllColors: builder.query<EntityDto[], QueryOptions | void>({
      queryFn: async () => {
        const response = await inventoriaApi.colors.findAllColors();

        const data = response?.data.content?.map?.(
          EntityCore.mapEntityResponseToEntityDto
        ) as EntityDto[];

        return { data };
      },
      providesTags: ['Colors'],
    }),
    addColor: builder.mutation<any, string>({
      queryFn: async (name) => {
        await inventoriaApi.colors.saveColor({ name });
        notification.success({ message: 'Color Added Successfully' });
        return { data: null };
      },
      invalidatesTags: ['Colors'],
    }),
    editColor: builder.mutation<any, EntityDto>({
      queryFn: async (color) => {
        await inventoriaApi.colors.updateColor(color.id, color);
        notification.success({ message: 'Color Edited Successfully' });
        return { data: null };
      },
      invalidatesTags: ['Colors'],
    }),
    // MODELS
    getAllModels: builder.query<EntityDto[], QueryOptions | void>({
      queryFn: async () => {
        const response = await inventoriaApi.models.findAllModels({
          queryOptions: { pageSize: 5000, pageNumber: 0 },
        });

        const data = response?.data.content?.map?.(
          EntityCore.mapEntityResponseToEntityDto
        ) as EntityDto[];

        return { data };
      },
      providesTags: ['Models'],
    }),
    addModel: builder.mutation<any, string>({
      queryFn: async (name) => {
        await inventoriaApi.models.saveModel({ name });
        notification.success({ message: 'Model Added Successfully' });
        return { data: null };
      },
      invalidatesTags: ['Models'],
    }),
    editModel: builder.mutation<any, EntityDto>({
      queryFn: async (model) => {
        await inventoriaApi.models.updateModel(model.id, model);
        notification.success({ message: 'Model Edited Successfully' });
        return { data: null };
      },
      invalidatesTags: ['Models'],
    }),
    // CATEGORIES
    getAllCategories: builder.query<CategoryDto[], QueryOptions | void>({
      queryFn: async () => {
        const response = await inventoriaApi.categories.findAllCategories();

        const data = response?.data?.map?.(
          CategoryCore.mapCategoryResponseToCategoryDto
        ) as CategoryDto[];

        return { data };
      },
      providesTags: ['Categories'],
    }),
    addCategory: builder.mutation<any, Omit<CategoryDto, 'id'>>({
      queryFn: async (category: Omit<CategoryDto, 'id'>) => {
        await inventoriaApi.categories.saveCategory(category);
        notification.success({ message: 'Category Added Successfully' });

        return { data: null };
      },
      invalidatesTags: ['Categories'],
    }),
    editCategory: builder.mutation<any, CategoryDto>({
      queryFn: async (category: CategoryDto) => {
        await inventoriaApi.categories.updateCategory(category.id, category);
        notification.success({ message: 'Category Edited Successfully' });

        return { data: null };
      },
      invalidatesTags: ['Categories'],
    }),
    // WAREHOUSES
    getAllWarehouses: builder.query<WarehouseResponse[], QueryOptions | void>({
      queryFn: async () => {
        const response = await inventoriaApi.warehouses.findAllWarehouses({
          queryOptions: { pageSize: 1000 }, // We always want to get all warehouses - pagination is not implemented for warehouses
        });

        const data = response.data.content;
        return { data };
      },
      providesTags: ['Warehouses'],
    }),
    addWarehouse: builder.mutation<WarehouseResponse, Warehouse>({
      queryFn: async (warehouse) => {
        const response =
          await inventoriaApi.warehouses.saveWarehouse(warehouse);
        const data = response.data;
        return { data };
      },
      invalidatesTags: ['Warehouses'],
    }),
    // PLATFORMS
    getAllPlatforms: builder.query<PlatformResponse[], void>({
      queryFn: async () => {
        const response = await inventoriaApi.platforms.findAllPlatforms();
        const data = response.data;
        return { data };
      },
      providesTags: ['Platforms'],
    }),
    addPlatform: builder.mutation<PlatformResponse, Platform>({
      queryFn: async (platForm) => {
        const response = await inventoriaApi.platforms.savePlatform(platForm);
        const data = response.data;
        return { data };
      },
      invalidatesTags: ['Platforms'],
    }),
    // PLATFORM PRODUCTS
    getPlatformProducts: builder.query<
      PlatformProductPageResponse,
      QueryOptions
    >({
      queryFn: async (queryOptions) => {
        const response =
          await inventoriaApi.platformProducts.findAllPlatformsProducts({
            queryOptions,
          });
        const data = response.data;
        return { data };
      },
      providesTags: ['Platforms'],
    }),
    // SHIPMENTS
    getAllShipments: builder.query<ShipmentSummaryPageResponse, QueryOptions>({
      queryFn: async (queryOptions) => {
        const response = await inventoriaApi.shipments.getAllShipments({
          queryOptions,
        });
        const data = response.data;
        return { data };
      },
      providesTags: ['Shipments'],
    }),
    getShipmentById: builder.query<ShipmentDetailsResponse, number>({
      queryFn: async (shipmentId) => {
        const response =
          await inventoriaApi.shipments.getShipmentById(shipmentId);
        const data = response.data;
        return { data };
      },
      providesTags: (_1, _2, id) => {
        return [{ type: 'Shipments' as const, id }];
      },
    }),
    startPreparingShipment: builder.mutation<void, number>({
      queryFn: async (shipmentId) => {
        const response =
          await inventoriaApi.shipments.startPreparingShipment(shipmentId);
        const data = response.data;
        return { data };
      },
      invalidatesTags: (_1, _2, id) => {
        return [{ type: 'Shipments' as const, id }];
      },
    }),
    finishPreparingShipment: builder.mutation<void, number>({
      queryFn: async (shipmentId) => {
        const response =
          await inventoriaApi.shipments.finishPreparingShipment(shipmentId);
        const data = response.data;
        return { data };
      },
      invalidatesTags: (_1, _2, id) => {
        return [{ type: 'Shipments' as const, id }];
      },
    }),
    //Products
    getProducts: builder.query<
      {
        products: ProductDto[];
        pagination: { pageSize: number; current: number; total: number };
      },
      QueryOptions
    >({
      queryFn: async (queryOptions) => {
        const response = await inventoriaApi.products.findAllProducts({
          queryOptions: {
            pageSize: Config.pagination.pageSize,
            ...queryOptions,
            pageNumber: (queryOptions?.pageNumber ?? 1) - 1,
          },
        });
        const dataMapped = response.data.content.map(
          ProductCore.mapProductResponseToProductDto
        );
        return {
          data: {
            products: dataMapped,
            pagination: {
              pageSize: response.data.paginationInfo.pageSize,
              current: queryOptions.pageNumber as number,
              total: response?.data?.paginationInfo?.totalNumberOfElements,
            },
          },
        };
      },
      providesTags: ['Products'],
    }),
    addProduct: builder.mutation<ProductResponse, Product>({
      queryFn: async (product) => {
        const response = await inventoriaApi.products.saveProduct(product);
        notification.success({ message: 'Product Added  Successfully' });
        const data = response.data;
        return { data };
      },
      invalidatesTags: ['Products'],
    }),
    editProduct: builder.mutation<ProductResponse, Product & { id: number }>({
      queryFn: async (product) => {
        const response = await inventoriaApi.products.updateProduct(
          product.id,
          product
        );
        notification.success({ message: 'Product Edited  Successfully' });
        const data = response.data;
        return { data };
      },
      invalidatesTags: ['Products'],
    }),
    // get decision-making request
    getDecisionMakingRequest: builder.query<
      {
        decisionMakingRequests: DecisionRequestResponse[];
        pagination: { pageSize: number; current: number; total: number };
      },
      QueryOptions
    >({
      queryFn: async (queryOptions) => {
        const response =
          await inventoriaApi.decisionRequest.findAllDecisionRequests({
            queryOptions: {
              pageSize: Config.pagination.pageSize,
              ...queryOptions,
              pageNumber: (queryOptions?.pageNumber ?? 1) - 1,
            },
          });
        return {
          data: {
            decisionMakingRequests: response.data.content,
            pagination: {
              pageSize: response.data.paginationInfo.pageSize,
              current: queryOptions.pageNumber as number,
              total: response?.data?.paginationInfo?.totalNumberOfElements,
            },
          },
        };
      },
      providesTags: ['Products'],
    }),
  }),
});

export const {
  useGetAllModelsQuery,
  useAddModelMutation,
  useEditModelMutation,
  useGetAllColorsQuery,
  useAddColorMutation,
  useEditColorMutation,
  useGetAllWarehousesQuery,
  useAddWarehouseMutation,
  useGetAllPlatformsQuery,
  useAddPlatformMutation,
  useGetAllCategoriesQuery,
  useAddCategoryMutation,
  useEditCategoryMutation,
  useGetProductsQuery,
  useAddProductMutation,
  useEditProductMutation,
  useGetDecisionMakingRequestQuery,
} = api;
