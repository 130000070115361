import { FormProps, Input, notification, Typography } from 'antd';
import { InventoriaForm } from '../../components/form/form';
import { useAddPlatformMutation } from '../../store/entityRtk/apiSlice';
import { Platform } from '../../api/generated/data-contracts';

export const PlatformsAddPage = () => {
  const [addPlatform, { isLoading }] = useAddPlatformMutation();
  const [form] = InventoriaForm.useForm<Platform>();

  const savePlatform: FormProps<Platform>['onFinish'] = async (platform) => {
    try {
      const response = await addPlatform(platform).unwrap();
      form.resetFields();
      notification.success({
        message: `Platform '${response.name}' added successfully!`,
      });
    } catch (e) {
      // Error handled by Axios interceptor
    }
  };

  return (
    <div>
      <Typography.Title level={2}>Add Platform</Typography.Title>
      <InventoriaForm<Platform>
        form={form}
        loading={isLoading}
        onFinish={savePlatform}
      >
        <InventoriaForm.Item<Platform>
          required
          name={'name'}
          label={'Name'}
          rules={[
            { required: true, message: 'Platform name is required' },
            {
              max: 200,
              message: 'Platform name cannot exceed 200 characters',
            },
          ]}
        >
          <Input placeholder='Platform name' />
        </InventoriaForm.Item>
        <InventoriaForm.Item<Platform>
          required
          name={'url'}
          label={'URL'}
          rules={[{ required: true, message: 'Platform URL is required' }]}
        >
          <Input placeholder='Platform URL' />
        </InventoriaForm.Item>
      </InventoriaForm>
    </div>
  );
};
