export enum MenuKeysEnum {
  PRODUCTS,
  COLORS,
  MODELS,
  CATEGORIES,
  TAGS,
  UPLOAD_EXCEL,
  WAREHOUSES,
  PLATFORM_PRODUCTS,
  PLATFORMS,
  SHIPMENTS,
  DECISION_MAKING_REQUEST,
}
