import { useState } from 'react';
import {
  AmazonOutlined,
  BookOutlined,
  BorderOuterOutlined,
  CodepenOutlined,
  HomeOutlined,
  LoginOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ProductOutlined,
  TruckOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Button, Layout, Menu, theme, Typography } from 'antd';
import { matchPath, NavLink, Outlet, useLocation } from 'react-router-dom';
import { InventoriaBreadCrumb } from '../../components/breadcrumb/breadcrumb';
import { routesMetadata } from '../../routes/router';
import { MenuKeysEnum } from '../../enums';
import { InventoriaRoutes } from '../../routes/routes';
import { RoutesMetadata } from '../../types';

const { Header, Sider, Content } = Layout;

export const MainLayout = () => {
  const [collapsed, setCollapsed] = useState(false);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const pathname = useLocation().pathname;

  const getActiveMenuKey = (pathname: string | null) => {
    const routes = Object.values(InventoriaRoutes);

    // Iterate over the known routes and match with the current pathname
    if (pathname) {
      for (const route of routes) {
        const match = matchPath(route, pathname);
        if (match) {
          return routesMetadata[route];
        }
      }
    }

    // Return null if no match is found
    return routesMetadata[InventoriaRoutes.PRODUCTS];
  };

  const routeMetadata: RoutesMetadata = getActiveMenuKey(pathname);

  return (
    <Layout style={{ height: '100vh' }}>
      <Sider
        style={{ background: '#FAFBFB', borderRight: '1px solid #E4E7EB' }}
        theme={'light'}
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div style={{ borderBottom: '1px solid #E4E7EB' }}>
          <Typography.Title
            style={{
              textAlign: 'center',
              fontWeight: 500,
              fontFamily: 'Rubik',
            }}
            level={3}
          >
            <CodepenOutlined /> Inventario
          </Typography.Title>
        </div>
        <Menu
          inlineIndent={16}
          mode='inline'
          style={{ background: '#FAFBFB', borderRight: 'unset' }}
          selectedKeys={[routeMetadata?.activeKey?.toString()]}
          items={[
            {
              key: 'Inventory',
              style: { fontFamily: 'Rubik', fontWeight: 400 },
              icon: <ProductOutlined />,
              label: 'Inventory',
              children: [
                {
                  key: MenuKeysEnum.PRODUCTS,
                  label: (
                    <NavLink to={InventoriaRoutes.PRODUCTS}>Products</NavLink>
                  ),
                  icon: <ProductOutlined />,
                },
                {
                  key: MenuKeysEnum.WAREHOUSES,
                  label: (
                    <NavLink to={InventoriaRoutes.WAREHOUSES}>
                      Warehouses
                    </NavLink>
                  ),
                  icon: <HomeOutlined />,
                },
                {
                  key: MenuKeysEnum.PLATFORMS,
                  label: (
                    <NavLink to={InventoriaRoutes.PLATFORMS}>Platforms</NavLink>
                  ),
                  icon: <AmazonOutlined />,
                },
                {
                  key: MenuKeysEnum.PLATFORM_PRODUCTS,
                  label: (
                    <NavLink to={InventoriaRoutes.PLATFORM_PRODUCTS}>
                      Platform Products
                    </NavLink>
                  ),
                  icon: <ProductOutlined />,
                },
                {
                  key: MenuKeysEnum.SHIPMENTS,
                  label: (
                    <NavLink to={InventoriaRoutes.SHIPMENTS}>Shipments</NavLink>
                  ),
                  icon: <TruckOutlined />,
                },
                {
                  key: MenuKeysEnum.DECISION_MAKING_REQUEST,
                  label: (
                    <NavLink to={InventoriaRoutes.DECISION_MAKING_REQUEST}>
                      Decision Making Request
                    </NavLink>
                  ),
                  icon: <LoginOutlined />,
                },
              ],
            },
            {
              key: 'Dictionaries',
              style: { fontFamily: 'Rubik', fontWeight: 400 },
              icon: <BookOutlined />,
              label: 'Dictionaries',
              children: [
                {
                  key: MenuKeysEnum.COLORS,
                  label: <NavLink to={InventoriaRoutes.COLORS}>Colors</NavLink>,
                  icon: <BorderOuterOutlined />,
                },
                {
                  key: MenuKeysEnum.MODELS,
                  label: <NavLink to={InventoriaRoutes.MODELS}>Models</NavLink>,
                  icon: <BorderOuterOutlined />,
                },
                {
                  key: MenuKeysEnum.CATEGORIES,
                  label: (
                    <NavLink to={InventoriaRoutes.CATEGORIES}>
                      Categories
                    </NavLink>
                  ),
                  icon: <BorderOuterOutlined />,
                },
              ],
            },
            {
              key: MenuKeysEnum.UPLOAD_EXCEL,
              style: { fontFamily: 'Rubik', fontWeight: 400 },
              label: (
                <NavLink to={InventoriaRoutes.EXCEL}>Upload Excel</NavLink>
              ),
              icon: <UploadOutlined />,
            },
          ]}
        />
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: '#FAFBFB',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Button
            type='text'
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => {
              setCollapsed(!collapsed);
            }}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
          <InventoriaBreadCrumb items={routeMetadata?.breadCrumb} />
        </Header>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
            overflow: 'hidden',
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
