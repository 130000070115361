import { Button, Flex, Space, TableColumnsType, Typography } from 'antd';
import { NavLink } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useGetAllWarehousesQuery } from '../../store/entityRtk/apiSlice';
import { WarehouseResponse } from '../../api/generated/data-contracts';
import { InventarioTable } from '../../components/table/inventarioTable';

const columns: TableColumnsType<WarehouseResponse> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 50,
    align: 'center',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
];

export const WarehousesListingPage = () => {
  const { data, isLoading } = useGetAllWarehousesQuery();
  return (
    <Space direction='vertical' size={'small'} style={{ width: '100%' }}>
      <Flex align={'center'} justify={'space-between'}>
        <Typography.Title>Warehouses</Typography.Title>
        <NavLink style={{ display: 'block' }} to={'/warehouses/add'}>
          <Button type={'primary'} icon={<PlusOutlined />}>
            Add Warehouse
          </Button>
        </NavLink>
      </Flex>

      <InventarioTable
        dataSource={data}
        columns={columns}
        loading={isLoading}
        rowKey={(record) => record.id}
        pagination={false}
      />
    </Space>
  );
};
