import { Flex, InputNumber } from 'antd';
import { useAppDispatch } from '../../store/useAppDispatch';
import { decisionMakingRequestActions } from '../../store/decision-making-request/decisionMakingRequestActions';
interface Props {
  value: number;
  productIndex: number;
  decisionIndex: number;
  decidedWarehouseShipmentIndex: number;
}

export const DecidedShipmentQuantityCellRenderer = (props: Props) => {
  const value = props.value;
  const dispatch = useAppDispatch();

  return (
    <Flex gap={5} align={'center'}>
      <InputNumber
        min={0}
        value={value}
        onChange={(value) => {
          dispatch(
            decisionMakingRequestActions.updateShipmentQuantity({
              productIndex: props.productIndex,
              decidedShipmentQuantity: value as number,
              decidedWarehouseShipmentIndex:
                props.decidedWarehouseShipmentIndex,
              decisionIndex: props.decisionIndex,
            })
          );
        }}
      />
    </Flex>
  );
};
