import { Button, Input, Popover, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { AddPopupContainerProps } from './entityTable.types';
import { useState } from 'react';

interface Props extends AddPopupContainerProps {
  addApi: (newItemName: string) => void;
  loading: boolean;
}

export const DefaultEntityAddPopover = ({
  entityName,
  addApi,
  loading,
}: Props) => {
  const [newItemName, setNewItemName] = useState('');
  const [open, setOpen] = useState(false);

  const saveEntity = () => {
    addApi(newItemName);
    setNewItemName('');
  };

  return (
    <Popover
      placement='leftTop'
      open={open}
      onOpenChange={(visible) => setOpen(visible)}
      content={
        <Space size={'small'} direction={'vertical'}>
          <Input
            ref={(el) => {
              setTimeout(() => el?.focus(), 500);
            }}
            value={newItemName}
            onPressEnter={saveEntity}
            onChange={(e) => setNewItemName(e.target.value)}
            placeholder={`Enter ${entityName} Name`}
          />
          <Button
            disabled={newItemName === ''}
            loading={loading}
            onClick={saveEntity}
            type={'primary'}
          >
            Add
          </Button>
        </Space>
      }
      title={`Adding New ${entityName}`}
      trigger='click'
    >
      <Button type={'primary'} icon={<PlusOutlined />}>
        Add New
      </Button>
    </Popover>
  );
};
