/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { S3ItemResponse } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class S3Items<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name CreatUploadImageUrl
   * @summary upload item
   * @request POST:/s3items/create-upload-image-url
   */
  creatUploadImageUrl = (
    query: {
      /** the file name */
      itemName: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<S3ItemResponse, any>({
      path: `/s3items/create-upload-image-url`,
      method: 'POST',
      query: query,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @name CreatUploadExcelUrl
   * @summary upload excel sheet
   * @request POST:/s3items/create-upload-excel-url
   */
  creatUploadExcelUrl = (
    query: {
      /** the file name */
      excelSheetName: string;
    },
    params: RequestParams = {}
  ) =>
    this.request<S3ItemResponse, any>({
      path: `/s3items/create-upload-excel-url`,
      method: 'POST',
      query: query,
      format: 'json',
      ...params,
    });
}
