import { TableProps } from 'antd';
import { ColumnGroupType, ColumnType } from 'antd/es/table/interface';
import { QueryOptions } from '../../api/generated/data-contracts';
import { AxiosResponse } from 'axios';

export type AntdColumnType<T> = ColumnGroupType<T> | ColumnType<T>;

export enum TableTypes {
  TEXT_FILTER = 'text_filter',
  NUMBER_FILTER = 'number_filter',
}

export interface InventarioColumnType<T> extends ColumnType<T> {
  type?: TableTypes[];
  filterFunction?: (text: string, record: T) => boolean;
}

export interface InventarioProps<T> extends TableProps<T> {
  columns?: InventarioColumnType<T>[];
  serverSideTable?: boolean;
  serverSideApiFunction?: (query?: {
    /** Paging, sorting, and filtration parameters */
    queryOptions?: QueryOptions;
  }) => Promise<AxiosResponse>;
}
