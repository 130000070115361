/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TaskResponse } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class Tasks<
  SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name FindTaskById
   * @summary Find task id to keep pulling on it while status is PENDING or IN_PROGRESS
   * @request GET:/tasks/{taskId}
   */
  findTaskById = (taskId: number, params: RequestParams = {}) =>
    this.request<TaskResponse, any>({
      path: `/tasks/${taskId}`,
      method: 'GET',
      format: 'json',
      ...params,
    });
}
