import { PlatformProductDto, ProductPlatformType } from '../../types';
import { inventoriaApi } from '../../api/inventoria-api-factory/inventoriaApiFactory';
import { PlatformProduct } from '../../api/generated/data-contracts';

export class PlatformProductCore {
  static getPlatformProductsByProductId(productId: number, platformId: number) {
    return inventoriaApi.platformProducts.findAllPlatformsProducts({
      queryOptions: {
        filter: `product.id == ${productId}; platform.id == ${platformId}`,
      },
    });
  }

  static savePlatformProduct(
    platFormProductForm: ProductPlatformType,
    productId: number,
    platformId: number
  ) {
    const productPlatform = this.mapPlatformProductFormToPlatformProduct(
      platFormProductForm,
      productId,
      platformId
    );
    return inventoriaApi.platformProducts.savePlatformProduct(productPlatform);
  }

  static mapPlatformProductFormToPlatformProduct(
    form: ProductPlatformType,
    productId: number,
    platformId: number
  ): PlatformProduct {
    return {
      name: form.name,
      url: form.url,
      platformSku: form.platformSku,
      productId,
      platformId,
    };
  }

  static mapPlatformProductToPlatformProductDto(
    platformProduct: PlatformProduct
  ): PlatformProductDto {
    return {
      name: platformProduct.name,
      platformId: platformProduct.platformId,
      productId: platformProduct.productId,
      platformSku: platformProduct.platformSku,
      url: platformProduct.url,
    };
  }
}
