import { ProductDto } from '../../../types';
import { Divider, Flex } from 'antd';
import { ProductCard } from '../product-card/productCard';
import styled from 'styled-components';
import { ProductGridFilters } from './productGridFilters';

const ProductGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  flex-grow: 1;
  ); /* Adjust as needed */
  grid-gap: 20px; /* Adjust as needed */
  height: 100%;
  overflow: auto;
  padding: 10px;
`;

interface Props {
  products: ProductDto[];
}

export const ProductGrid = (props: Props) => {
  return (
    <Flex style={{ height: 'calc(100vh - 350px)' }}>
      <ProductGridFilters />
      <Divider style={{ height: '100%' }} type={'vertical'} />
      <ProductGridContainer>
        {props?.products?.map?.((product: ProductDto) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </ProductGridContainer>
    </Flex>
  );
};
