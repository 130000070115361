import { Button, Flex, Skeleton, Space, Tag, Typography } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { api } from '../../store/entityRtk/apiSlice';
import { ShipmentProductCard } from './shipmentProductCard';
import { BlockOutlined, CheckOutlined } from '@ant-design/icons';
import { getStatusColor, ShipmentStatus } from './shipmentStatus';
import _ from 'lodash';

const ShipmentProductGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    flex-grow: 1;
    flex-wrap: wrap;
); /* Adjust as needed */
    grid-gap: 20px; /* Adjust as needed */
    height: 100%;
    overflow: auto;
`;
//
// const Detail = styled.span`
//   color: gray;
//   font-size: 16px;
// `;

export const ShipmentDetailsPage = () => {
  const { shipmentId } = useParams();
  const shipmentIdNumber = Number(shipmentId);
  const { data: shipment, isLoading } =
    api.useGetShipmentByIdQuery(shipmentIdNumber);
  const [startPreparingShipment] = api.useStartPreparingShipmentMutation();
  const [finishPreparingShipment] = api.useFinishPreparingShipmentMutation();

  return (
    <Space direction='vertical' size={'middle'} style={{ width: '100%' }}>
      <Flex align={'center'} justify={'space-between'}>
        <Flex vertical>
          <Typography.Title level={2}>Shipment</Typography.Title>
          <div style={{ marginTop: -8 }}>
            <Tag style={{ alignSelf: 'flex-start' }}>#{shipment?.id}</Tag>
            <Tag
              color={getStatusColor(shipment?.status)}
              style={{ alignSelf: 'flex-start' }}
            >
              {shipment?.status}
            </Tag>
          </div>
        </Flex>
        <Space size={'small'}>
          <Button
            type={'primary'}
            icon={<BlockOutlined />}
            disabled={shipment?.status !== ShipmentStatus.IDLE}
            onClick={() => startPreparingShipment(shipmentIdNumber)}
          >
            Start Preparing
          </Button>{' '}
          <Button
            type={'primary'}
            disabled={shipment?.status !== ShipmentStatus.PREPARING}
            icon={<CheckOutlined />}
            onClick={() => finishPreparingShipment(shipmentIdNumber)}
          >
            Finish Preparing
          </Button>
        </Space>
      </Flex>
      <ShipmentProductGrid>
        {_.range(1, 10).map((v) => (
          <Skeleton key={v} active loading={isLoading}></Skeleton>
        ))}
        {shipment?.shipmentProducts.map((sp) => (
          <ShipmentProductCard key={sp.id} shipmentProduct={sp} />
        ))}
      </ShipmentProductGrid>
    </Space>
  );
};
