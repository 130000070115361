import { EntityTable } from '../../../components/entity-table/entityTable';
import {
  useAddModelMutation,
  useEditModelMutation,
} from '../../../store/entityRtk/apiSlice';
import { useGetAllLookups } from '../../../hooks/useGetAllLookups';

export const ModelsListingPage = () => {
  const [addModel, { isLoading }] = useAddModelMutation();
  const [editModel, { isLoading: isEditLoading }] = useEditModelMutation();
  const { models } = useGetAllLookups();

  return (
    <EntityTable
      dataSource={models}
      loading={isLoading || isEditLoading}
      entityName={'Model'}
      editApi={(entity) => {
        editModel(entity);
      }}
      serverSideTable={false}
      addApi={(newItemName) => {
        addModel(newItemName);
      }}
    />
  );
};
