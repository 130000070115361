import { mapAntdTableFiltersToRSQL } from '../../../../utils';
import type { FilterValue } from 'antd/es/table/interface';
import { TablePaginationConfig } from 'antd';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../store/useAppDispatch';
import {
  addFilter,
  changeFilters,
  setPagination,
} from '../../../../store/product-listing-page/productListingPageActions';
import { ProductListingPageFilters } from '../../../../store/types';
import {
  selectFilters,
  selectPagination,
} from '../../../../store/product-listing-page/productListingPage';
import { useGetProductsQuery } from '../../../../store/entityRtk/apiSlice';
import { useEffect } from 'react';
import { Config } from '../../../../config';

export const useGetProductsData = () => {
  const productListingPagination = useAppSelector(selectPagination);
  const filters = useAppSelector(selectFilters);
  const { data } = useGetProductsQuery({
    filter: mapAntdTableFiltersToRSQL(filters, FILTER_MAP),
    pageSize: Config.pagination.pageSize,
    pageNumber: productListingPagination.current,
  });
  const dispatch = useAppDispatch();
  const products = data?.products;

  const onChangeFilterTable = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>
  ) => {
    dispatch(changeFilters(filters));
    dispatch(
      setPagination({
        ...productListingPagination,
        current: pagination.current ?? 1,
      })
    );
  };

  const onChangeFilterGrid = (filter: {
    key: keyof ProductListingPageFilters;
    value: any;
  }) => {
    dispatch(addFilter(filter));
  };

  useEffect(() => {
    dispatch(
      setPagination({
        ...productListingPagination,
        total: data?.pagination.total ?? 0,
      })
    );
  }, [products?.length]);

  return {
    total: productListingPagination.total,
    products,
    onChangeFilterTable,
    onChangeFilterGrid,
    filters,
    current: productListingPagination.current,
  };
};

const FILTER_MAP = Object.freeze({
  model: 'model.id',
  subCategory: 'subCategory.id',
  color: 'color.id',
  category: 'category.id',
});
