import Keycloak from 'keycloak-js';
import { AxiosInstance, InternalAxiosRequestConfig } from 'axios';

export const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_BASE_URL!,
  realm: process.env.REACT_APP_KEYCLOAK_REALM!,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID!,
});

const refreshToken = (minValidity = 5) => {
  return new Promise<void>((resolve, reject) => {
    keycloak
      .updateToken(minValidity)
      .then(() => resolve())
      .catch((error) => reject(error));
  });
};

const keycloakAuthorizationRequestInterceptor = (
  request: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> => {
  return refreshToken()
    .then(() => {
      request.headers.Authorization = `Bearer ${keycloak.token}`;
      return Promise.resolve(request);
    })
    .catch(() => {
      keycloak.login();
    }) as Promise<InternalAxiosRequestConfig>;
};

const keycloakUnauthorizedResponseInterceptor = (error: any) => {
  if (error.response && error.response.status === 401) {
    window.location.replace('/unauthorized');
  }
  return Promise.reject(error);
};

export const addKeycloakInterceptors = (axios: AxiosInstance): void => {
  axios.interceptors.request.use(keycloakAuthorizationRequestInterceptor);
  axios.interceptors.response.use(
    (response) => Promise.resolve(response),
    keycloakUnauthorizedResponseInterceptor
  );
};
