export const InventoriaRoutes = Object.freeze({
  HOME: '/',
  PRODUCTS: '/products',
  ADD_PRODUCT: '/products/add',
  EDIT_PRODUCT: '/products/:id',
  COLORS: '/colors',
  MODELS: '/models',
  CATEGORIES: '/categories',
  TAGS: '/tags',
  EXCEL: '/excel',
  WAREHOUSES: '/warehouses',
  WAREHOUSES_ADD: '/warehouses/add',
  PLATFORM_PRODUCTS: '/platform-products',
  PLATFORMS: '/platforms',
  PLATFORMS_ADD: '/platforms/add',
  PLATFORM_PRODUCT: '/platform-product/:productId',
  DECISION_MAKING_REQUEST: '/decision-making-request',
  ADD_DECISION_MAKING_REQUEST: '/decision-making-request/add',
  DECISION_MAKING_REQUEST_DETAILS: '/decision-making-request/details/:id',
  SHIPMENTS: '/shipments',
  SHIPMENT: '/shipments/:shipmentId',
});
