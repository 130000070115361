import axios from 'axios';
import { notification } from 'antd';
import queryString from 'query-string';
import { BaseUrl } from '../../constants';
import { addKeycloakInterceptors } from '../../keycloak/keycloak';

export const inventoriaInstance = axios.create({
  baseURL: BaseUrl,
  headers: { 'Content-Type': 'application/json;charset=UTF-8' },
  paramsSerializer: (params) => {
    // Default paramsSerializer does not explode objects e.g.
    // params = { queryOptions: {filter: 'id == 1'} } is serialized to
    // queryOptions[filter]=id%20==%201 while what we want is
    // filter=id%20==%201 - this is what this paramsSerializer does
    return Object.entries(params)
      .map(([key, value]) => {
        if (typeof value === 'object') {
          return queryString.stringify(value);
        } else {
          return queryString.stringify({ [key]: value });
        }
      })
      .join('&');
  },
});

inventoriaInstance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

inventoriaInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    notification.error({ message: error?.response?.data?.errors?.[0]?.reason });
    return Promise.reject(error);
  }
);

addKeycloakInterceptors(inventoriaInstance);
