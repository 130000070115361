import { MainLayout } from './layouts/main-layout/mainLayout';
import './App.css';
import { ConfigProvider } from 'antd';

function App() {
  return (
    <div>
      <ConfigProvider>
        <MainLayout />
      </ConfigProvider>
    </div>
  );
}

export default App;
